.plan-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #ffffff;
    box-shadow: 0px 8px 40px 5px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    margin-bottom: 30px;

    .plan-title {
        color: var(--primary);
        margin: 25px 0px;
    }

    h3 {
        font-weight: 400;
    }

    .button-container {
        width: 70%;
        margin: 10px 0px 35px;
    }
}
