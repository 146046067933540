.student-course-card-container {
    h4 {
        line-height: 1.3 !important;
    }
    .student-course-card {
        height: 80px;
        width: 100%;
        color: var(--white);
        border-radius: 24px;
        padding: 25px 25px 25px 25px;
        display: flex;
        flex-direction: column;
        background-color: var(--fuchsia);
        .top-container {
            display: flex;
            flex-direction: column;
            gap: 10px;

            div {
                display: flex;
                flex-direction: column;
                justify-content: space-between;

                p {
                    font-size: 10px;
                    color: var(--fuchsia);
                    margin: 0;
                }
            }

            h3 {
                margin: 0px;
                color: var(--white);
                font-weight: 400;
                font-size: 23px;
            }
            
            h4 {
                margin: 0px;
                color: var(--white);
                font-weight: 300;
            }
        }
        .disabled-top-container {
            gap: 0px;
        }
        .bottom-container {
            display: flex;
            justify-content: space-between;
            
            h4 {
                color: var(--white);
            }
        }
    }
    .fuchsia {
        background-color: var(--fuchsia);
    }
    .mustard {
        background-color: var(--mustard);
    }
    .primary {
        background-color: var(--primary);
    }
    .disabled {
        background-color: var(--light-gray);
        cursor: not-allowed;
    }
}

