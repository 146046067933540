.password-recovery-container {
    padding-top: 20px;
    .auth-title {
        color: var(--primary);
        margin-top: 0;
    }
    .form-container {
        max-width: 320px;
        margin-bottom: 50px;
        margin-top: 70px;

        .text-field {
            margin-bottom: 60px;
            p {
                color: var(--fuchsia);
            }
        }

        h4 {
            font-weight: 300;
            text-align: center;
            margin-top: 30px;
            a {
                text-decoration: none;
                color: var(--primary);
                font-weight: 500;
            }
        }
    }
}
