.teacher-profile {
    padding: 0 60px;

    header {
        text-align: center;
        margin-bottom: 80px;
        position: relative;

        h3 {
            font-size: 19px;
            color: var(--black);
            margin: 0;
        }

        .back {
            position: absolute;
            width: 25px;
            height: 25px;
            border-radius: 5px;
            background-color: var(--bc);
            top: 50%;
            left: 0;
            transform: translateY(-50%);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            padding: 5px;
            transition: all 0.3s ease;
        }
        .back:hover {
            background-color: var(--light-gray);
        }
    }
}
.teacher-profile-details {
    display: block;
    background-color: var(--bc);
    border-radius: 10px;
    position: relative;
    padding-bottom: 10px;

    .avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        background-size: cover;
        background-position: center center;
        margin: 0 auto -30px;
        position: relative;
        top: -50px;
        img {
            width: 100%;
            height: 100%;
            overflow: hidden;
        }
    }
    .price {
        position: absolute;
        top: 10px;
        right: 10px;
        background: var(--light-blue);
        color: var(--primary);
        font-weight: 600;
        padding: 10px 20px;
        border-radius: 20px;
        margin: 16px;
    }
    .wrapper {
        padding: 0 20px;
        display: block;
        align-items: center;
        .userinfo {
            text-align: center;

            h3 {
                margin: 0;
                font-size: 18px;
            }

            p {
                font-size: 15px;
                color: var(--dark-gray);
                margin: 10px 0;
            }

            .icon {
                color: var(--gold);
                border: none;
            }
        }
        .metaData-container {
            display: flex;
            justify-content: center;
            padding: 5px 0px;
            .metaData {
                padding: 5px;
            }
        }
    }
}
.profile-card-body {
    h3 {
        text-align: left;
        font-size: 18px;
        margin-bottom: 5px;
        color: var(--black);
    }
    .about {
        .about-body {
            background-color: var(--bc);
            padding: 20px;
            border-radius: 10px;

            p {
                font-size: 14px;
                line-height: 23px;
            }
        }
    }
    .background {
        .background-body {
            background-color: var(--bc);
            padding: 15px 20px;
            border-radius: 10px;
            font-size: 16px;
            p {
                font-size: 14px;
                line-height: 23px;
            }
            svg {
                color: var(--gray-blue);
            }
        }
        .education-container,
        .university-container {
            display: flex;
            gap: 10px;
            height: 35px;
            align-items: center;
        }
    }
    .teacher-courses {
        .courses {
            display: flex;
            gap: 7px;
            span {
                padding: 5px 10px;
                border-radius: 10px;
                margin-top: 15px;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }
}

.mustard {
    background-color: #fff2d0;
    color: var(--mustard);
}
.fuchsiaa {
    background-color: #f4a8b6;
    color: var(--fuchsia);
}
.primary {
    background-color: #a6ade8;
    color: var(--primary);
}

@media screen and (max-width: 600px) {
    .metaData-container {
        flex-direction: column;
        align-items: center;
    }
}
