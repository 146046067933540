.simple-checkbox { 
    display: inline-block;
    input { 
        display: none; 
    }
    label { 
        cursor: pointer;
        display: inline-block; 
        position: relative; 
        width: 20px; 
        height: 20px; 
        background: #FFF; 
        border: 1px solid var(--primary); 
        border-radius: 30px; 
        transition: all .3s ease; 
    }
    label i { 
        position: absolute; 
        top: 50%; 
        left: 50%; 
        transform: translate( -50%, -50% ) scale(0); 
        font-size: 9px; 
        margin-top: 1.5px; 
        color: #FFF; 
        transition: transform 500ms cubic-bezier(.26,2,.46,.71); 
    }
    input:checked ~ label { 
        background: var(--primary); 
    }
    input:checked ~ label i { 
        transform: translate( -50%, -50% ) scale(1.1); 
    }
}

.disabled {
    cursor: not-allowed;
    background-color: var(--bc);

    label {
        background: var(--bc);
        border: 1px solid var(--black); 
    }
}

.dark .simple-checkbox label { 
    background: #343b49; 
    border-color: #3a4252; 
}