.back-bar-container {
    display: flex;
    padding: 10px 0px 10px 15px;
    border-bottom: 1px solid var(--light-gray);
    align-items: center;
    gap: 25px;

    .icon {
        padding: 7px 10px;
        border-radius: 100%;
        border: 1px solid var(--light-gray);
        cursor: pointer;
    }
    .icon:hover {
        background-color: var(--bc);
        color: var(--primary);
        transition: all .3s ease;
    }
    h2 {
        margin: 0px;
        color: var(--dark-gray);
        font-size: 1.5em;
    }
}