.main-container {
    .left-panel-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        max-width: 545px !important;
        min-height: calc(100vh - 220px) !important;

        .calendar-card {
            padding: 20px 75px;

            .calendar-container {
                overflow: auto;

                .overlay-loader {
                    top: 10%;
                }
            }
        }

        .calendar-container {
            align-items: center;
            text-align: center;
            position: relative;

            .overlay-info {
                position: absolute;
                top: 105px;
                left: 0;
                width: 100%;
                height: 200px;
                background: rgba($color: #fff, $alpha: 0.5);

                h3 {
                    position: absolute;
                    color: var(--dark-gray);
                    top: 50%;
                }
            }
        }
        .available-times {
            gap: 10px;
            padding: 30px 0;
        }
        .react-calendar {
            max-width: 100%;
            min-width: 365px;
            background: white;
            line-height: 1.125em;

            abbr {
                text-decoration: none;
            }

            button {
                padding: 16px;
                border-radius: 100%;
            }

            .react-calendar__navigation__arrow {
                background-color: var(--bc);
                border-radius: 100%;
                transition: all 0.4s ease;
            }

            .react-calendar__navigation__arrow:hover {
                background-color: var(--primary) !important;
                border-radius: 100%;
                color: var(--white);
            }

            .react-calendar__year-view__months__month,
            .react-calendar__decade-view__years__year,
            .react-calendar__century-view__decades__decade {
                border-radius: 16%;
            }

            .react-calendar__navigation__label:focus .react-calendar__navigation__label:hover {
                background: var(--bc) !important;
            }

            .react-calendar__navigation__label {
                border-radius: 10px;
                transition: all 0.3s ease;
            }
        }
    }

    .right-panel-container {
        max-height: 100%;
        border-left: 1px solid var(--light-gray);
        overflow: auto;

        .teachers {
            padding: 20px 75px;
        }

        .cards-container-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 0px;

            h3 {
                margin-top: 6px;
                font-weight: 600;
            }

            .filter-icon {
                background-color: var(--bc);
                padding: 8px 8px;
                border-radius: 10px;
                width: 25px;
                height: 25px;
                line-height: 28px;
                text-align: center;
                cursor: pointer;
                transition: all 0.3s ease;

                svg {
                    width: 16px;
                    height: 16px;
                }
            }
            .filter-icon:hover {
                background-color: var(--light-gray);
            }
        }
        .cards-container {
            width: 100%;
            overflow: auto;
            position: relative;
        }
    }
}

.skip-container {
    display: none;
}

.confirmation {
    display: none;
}

@media screen and (max-width: 992px) {
    .main-container {
        min-height: calc(100vh - 220px) !important;
        .left-panel-container.show {
            display: none;
        }
        .right-panel-container.hide {
            border: none;

            .teachers {
                padding: 20px 35px;
            }
        }

        .left-panel-container.hide {
            display: block;
        }
        .right-panel-container.show {
            display: none;
        }
    }

    .main-container.hide {
        display: none;
        min-height: 100% !important;
    }
    .confirmation.show {
        display: block;
    }
}

@media screen and (max-width: 768px) {
    h3 {
        .skip {
            display: none;
            height: 100% !important;
        }
    }

    .skip-container {
        display: block;
        text-align: center;
        h3 {
            a {
                text-decoration: none;
                color: var(--primary);
            }
        }

        .back-container {
            display: block;
        }
    }
}

@media screen and (max-width: 500px) {
    .calendar-card {
        padding: 20px 10px !important;
    }
}

.buttons-container {
    display: flex;
    flex-direction: column;
    gap: 15px;

    button {
        font-size: 16px;
    }

    h3 {
        margin-top: 0px;
    }
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

.buttons-container {
    width: 100%;
}

.schedule-modal-container {
    text-align: center;
}

.react-calendar--doubleView {
    width: 700px;
}
.react-calendar--doubleView .react-calendar__viewContainer {
    display: flex;
    margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
    width: 50%;
    margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}
.react-calendar button {
    margin: 0;
    border: 0;
    outline: none;
}
.react-calendar button:enabled:hover {
    cursor: pointer;
}
.react-calendar__navigation {
    display: flex;
    height: 44px;
    margin-bottom: 1em;
    gap: 10px;
}
.react-calendar__navigation button {
    min-width: 44px;
    background: none;
}
.react-calendar__navigation button:disabled {
    background-color: #f0f0f0;
}
.react-calendar__navigation__arrow {
    background-color: var(--bc);
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__month-view__weekdays {
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
    padding: 0.5em;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    font-weight: bold;
}
.react-calendar__month-view__days__day--weekend {
    // color: var(--black);
}
.react-calendar__month-view__days__day--neighboringMonth {
    color: #757575;
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: 2em 0.5em;
}
.react-calendar__tile {
    max-width: 100%;
    padding: 10px 6.6667px;
    background: none;
    text-align: center;
    line-height: 16px;
    border: 2px solid #fff !important;
    transition: all 0.3s ease;
}
.react-calendar__tile:disabled {
    background-color: var(--white);
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
    background-color: #e6e6e6;
}
.react-calendar__tile--now {
    color: var(--primary);
    transition: all 0.3s ease;
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
    background: var(--light-gray);
    color: var(--black);
}
.react-calendar__tile--hasActive {
    background: var(--primary);
    transition: all 0.3s ease;
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
    background: var(--light-gray);
    color: var(--primary);
}
.react-calendar__tile--active {
    background: var(--primary) !important;
    color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: var(--primary);
    color: white;
    transition: all 0.3s ease;
}
.react-calendar__tile.selected-date:not(.react-calendar__tile--active) {
    background: rgba($color: #6371e0, $alpha: 0.16);
    color: var(--primary);
}
.react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6e6e6;
}

.thumb-vertical {
    position: relative;
    display: block;
    width: 10px !important;
    height: 274px;
    transform: translateY(0px);
    cursor: pointer;
    border-radius: inherit;
    background-color: var(--dark-gray);
    border-radius: 50px;
    left: -2px;
    opacity: 0.7;
}
