.update-call-log-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    .update-log-buttons-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
    }
}
