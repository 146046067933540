.class-layout-container {
    display: flex;
    // -webkit-padding-start: 108px;
    // padding-inline-start: 108px;

    .body-container {
        padding: 20px 45px 45px 20px;
        flex: 4;
        border-right: 1px solid var(--light-gray);
    }
    .classes-sidebar-container {
        max-height: 60vh;
        padding: 0px 15px 15px 15px;
        flex: 3;
    }
}

@media screen and (max-width: 992px) {
    .class-layout-container {
        .classes-sidebar-container {
            display: none;
        }
    }
}
