.form-container {
    max-width: 320px;

    .auth-title {
        color: var(--primary);
        margin-top: 0;
    }
    h4 {
        color: var(--dark-gray)
    }
    .social-buttons-container {
        text-align: center;
        margin-top: 40px;
        h4 {
            color: var(--dark-gray);
        }

        .social-buttons {
            display: flex;
            justify-content: space-between;
        }
    }

    h4 {
        font-weight: 400;
        margin: 40px 0px;
        a {
            color: var(--primary);
            font-weight: 600;
            text-decoration: none;
        }
    }
}