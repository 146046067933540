.credit-card-container {
    .credit-card-header {
        text-align: center;
        h4 {
            margin: 0;
        }
    }
    .credit-card-inputs {
        .card-number {
            display: flex;
            align-items: end;
            .card-input {
                flex: 1;
            }
            .card-image {
                svg {
                    margin: 0px 0px 15px 10px;
                }
            }
        }
        .cvc-expiry {
            display: flex;
            justify-content: space-between;
            gap: 10px;
            .expiry-date {
                width: 100%;
            }
            .cvc {
                width: 100%;
            }
        }
    }
}
