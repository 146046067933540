.event-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    background-color: var(--white);
    border: 1px solid var(--light-gray);
    border-radius: 26px;
    padding: 20px 25px;
    color: var(--dark-gray);
    margin: 25px;
    .event-top {
        display: flex;
        align-items: center;
    }
    .event-bottom {
        display: flex;
        align-items: start;
        justify-content: space-between;
        .event-body {
            flex: 3;
        }
        .event-button {
            flex: 2;
            flex-grow: 0;
            width: 100%;
            margin-left: 20px;
        }
    }
}