.auth-container {
    padding-bottom: 40px;
    .image-slider-container {
        .carousel {
            padding: 0 0 40px;

            h4 {
                color: var(--dark-gray);
            }
            .image-slider-body {
                // display: flex;
                // flex-direction: column;
                // align-items: center;
                text-align: left;
                h4 {
                    margin: 0;
                }
            }
            .control-dots {
                li {
                    width: 8px;
                    height: 8px;
                    margin: 0 5px;
                    box-shadow: none;
                    background: var(--light-blue);
                    opacity: 1;
                    transition: all 0.3s ease;
                }
                .selected {
                    width: 16px;
                    background: var(--primary);
                    border-radius: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 992px) {
    .image-slider-container {
        display: none;
    }
}
