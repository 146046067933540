.create-payment-container {
    .payment-buttons-container {
        display: flex;
        justify-content: center;
        padding: 15px 0px 30px 0px;
        gap: 15px;

        span {
            color: var(--dark-gray);
            font-size: 1rem;
            background-color: var(--bc);
            padding: 5px 10px;
            border-radius: 10px;
        }
        .selected {
            background-color: var(--light-gray);
            color: var(--black);
            transition: all 0.2s ease;
        }
        .span:hover {
            background-color: var(--light-gray);
            color: var(--black);
            transition: all 0.2s ease;
        }
    }
    .dropdown-container {
        p {
            margin: 0;
            font-size: 10px;
            margin-left: 10px;
            font-weight: 500;
        }
    }
}
