.course-tab-bar-container {
    display: flex;
    padding: 13px 0px 13px 25px;
    gap: 15px;
    border-bottom: 1px solid var(--light-gray);
    h3 {
        margin: 0px;
    }
    .tab-container {
        h3 {
            color: var(--gray-blue);
            font-weight: 400;
        }
        padding: 5px;
    }
    .tab-container:hover {
        color: var(--gray);
        padding: 5px;
        transition: all 0.3s ease;
    }
    .tab-container.selected {
        h3 {
            color: var(--primary);
        }
    }
}

@media screen and (max-width: 500px) {
    .course-tab-bar-container {
        padding: 13px 0px 13px 0px;
        position: fixed;
        bottom: 0;
        width: 100vw;
        background-color: var(--white);
        justify-content: center;
        overflow: auto;
        z-index: 10;

        .tab-container {
            h3 {
                font-size: 12px !important;
            }
        }
    }
}
