.progress-diagram-container {
    
    .progress-diagram-top {
        .course-cards-container {
            padding: 15px 25px;
            h3 {
                font-weight: 400;
                color: var(--new-dark-gray);
            }
            .course-cards {
                width: 625px;
                overflow-x: auto;
                display: flex;
                gap: 30px;
            }
        }
    }

    .progress-diagram-bottom {
        display: flex;
        padding: 0px 25px;
        align-items: start;
        gap: 40px;
        .test-result-cards-container {
            width: 100%;

            h3 {
                font-weight: 400;
                color: var(--new-dark-gray);
            }
            .test-result-cards {
                margin-top: 30px;
                display: flex;
                flex-direction: column;
                gap: 5px;
                max-height: 325px;
                overflow: hidden;
                overflow-y: auto;
            }
        }
        .test-chart-container {
            width: 100%;
            h3 {
                font-weight: 400;
                color: var(--new-dark-gray);
            }
            .chart {
                margin-top: 30px;
            }
        }
    }
}