.attendance-records-container {
    .course-cards-container {
        padding: 15px 25px;
        h3 {
            font-weight: 400;
            color: var(--new-dark-gray);
        }
        .course-cards {
            width: 625px;
            overflow-x: auto;
            display: flex;
            gap: 30px;
        }
    }

    .info-cards-container {
        padding: 15px 15px;
        border-radius: 14px;
        background-color: var(--bc);
        display: flex;
        justify-content: space-around;
    }
     

    .attendance-records-table-container {
        margin-top: 33px;
        h3 {
            padding-left: 25px;
            font-weight: 400;
            color: var(--new-dark-gray);
            margin-bottom: 20px;
        }
        .sub-text {
            color: var(--dark-gray);
            font-weight: 600;
        }
        .title.sub-text {
            white-space: nowrap;
        }
    }
}