.leads-statistics-container {
  height: 100px;
  width: 100%;
  max-width: 240px;
  border-radius: 24px;
  padding: 15px;
  background-color: var(--bc);
  display: flex;
  gap: 15px;
  .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: space-around;
    margin-bottom: 14px;
    .upper-container {
      .numbers-container {
        display: flex;
        align-items: baseline;
        gap: 5px;
        h2 {
          position: relative;
          font-size: 20px;
          margin: 0;
        }
        h4 {
          position: relative;
          bottom: 2px;
          font-size: 12px;
        }
      }
    }
    .bottom-container {
      p {
        color: var(--gray-blue);
        margin-top: -10px;
      }
    }
  }
}
.leads-statistics-container.selected {
  border: 1px solid var(--primary);
}
