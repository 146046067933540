.new-teacher-container {
    form {
        display: flex;
        gap: 30px;
        .right-container {
            width: 100%;
            h3 {
                margin: 0 0 22px 0;
            }
            .image-container {
                display: flex;
                justify-content: center;
                position: relative;
                img {
                    margin-right: 20px;
                    margin-top: 8px;
                    width: 88px;
                    height: 88px;
                    border-radius: 50%;
                    overflow: hidden;
                    background-size: cover;
                    background-position: center center;
                }

                span {
                    background-color: var(--light-gray);
                    padding: 4px 7px;
                    border-radius: 50%;
                    position: absolute;
                    top: 70px;
                    right: calc(50% - 35px);
                }
                input {
                    display: none;
                }
            }
        }

        .middle-container {
            width: 100%;
            h3 {
                margin: 0 0 16px 0;
            }
        }

        .left-container {
            width: 100%;
            h3 {
                margin: 0 0 16px 0;
            }
            .education-container {
                margin-top: 29px;
                .courses-container {
                    .courses {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                    }
                }
            }
        }
    }
}
