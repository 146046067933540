.date-picker {
    label {
        color: rgba(63, 73, 84, 1);
        font-size: 10px;
        font-weight: 500;
        box-sizing: border-box;
        margin: 20px 0px 5px 10px;
    }
    input {
        border: 1px solid var(--light-gray);
        border-radius: 25px;
        padding: 23px 0px 23px 20px;
        height: 2.5rem;
        color: var(--black);
        font-size: 12px;
        transition: all 0.3s ease;
    }
    input:focus {
        padding: 23px 0px 23px 20px;
        border: 1px solid var(--dark-gray);
        background: var(--bc);
        box-shadow: none;
    }
    input::placeholder {
        font-weight: normal;
        font-size: 0.9rem;
    }

    > div {
        div + div {
            align-self: start;
            color: var(--fuchsia);
            font-weight: 300;
            font-size: 12px;
            margin: 5px 0 0;
            padding-inline-start: 5px;
        }
    }
}

.phone-input {
    label {
        color: rgba(63, 73, 84, 1);
        font-size: 10px;
        font-weight: 500;
        box-sizing: border-box;
        margin: 20px 0px 5px 10px;
        align-self: start;

        abbr {
            display: none;
        }
    }
    > div {
        display: flex;
        align-items: center;
        border: 1px solid var(--light-gray) !important;
        border-radius: 25px;
        padding: 2px 9px;
        box-shadow: none;
        height: auto;

        button {
            // padding: 13px 0px 0px 8px;
        }

        div {
            // height: 2.5rem;
            color: var(--black);
            font-size: 12px;
            transition: all 0.3s ease;
        }

        input {
            height: 2.5rem;
            color: var(--black);
            font-size: 12px;
            transition: all 0.3s ease;
        }
        input:focus {
            // padding: 23px 0px 23px 20px;
            // border: 1px solid var(--dark-gray);
            // background: var(--bc);
            // box-shadow: none;
        }
        input::placeholder {
            font-weight: normal;
            font-size: 0.9rem;
        }
    }
}
.phone-input.error {
    > div {
        border: 1px solid var(--fuchsia);
        box-shadow: 0 0 0 1px var(--fuchsia);
    }

    input {
        color: var(--fuchsia);
    }
}
