.courses-card-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: #ffffff;
    box-shadow: 0px 8px 40px 5px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    width: 100%;

    .header-container {
        margin: 40px 10% 0px;

        h2 {
            color: var(--primary);
        }
    }

    .body-container {
        display: flex;
        justify-content: space-around;
        margin: 20px 30px;

        .course {
            display: flex;
            flex-direction: column;
            align-items: center;

            .course-logo {
                cursor: pointer;

                img {
                    filter: grayscale(1);
                    opacity: 0.6;
                    transition: all 0.3s ease;
                }
            }

            .course-title {
                h2 {
                    color: var(--dark-gray);
                }
            }
        }

        .course.active {
            .course-logo {
                img {
                    opacity: 1;
                    filter: grayscale(0);
                }
            }

            .course-title {
                h2 {
                    color: var(--primary);
                }
            }
        }
    }

    .footer-container {
        margin: 0px 30% 40px;
    }
}

@media screen and (max-width: 768px) {
    .courses-card-container {
        margin: 10px 0px;

        .body-container {
            justify-content: start;
            overflow: scroll;

            .course {
                padding: 0 30px;
            }
        }
    }
}
