.session-tab-page-container {
    padding-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 24px;

    .navigation-bar {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .navigation-tabs {
            display: flex;
            gap: 7px;

            .selected {
                border-color: var(--primary);
                p {
                    color: var(--primary);
                }
            }
            span {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 21px;
                width: 71px;
                border-radius: 30px;
                border: solid 1px var(--light-gray);
                padding: 7px 20px;

                p {
                    font-size: 14px;
                    font-weight: 400;
                    line-height: 21px;
                    color: var(--light-gray);
                }
            }
        }

        .plan-details {
            padding: 3px 20px;
            border-radius: 30px;
            background: var(--light-primary);

            p {
                color: var(--primary);
            }
        }
    }
}

.sessions-sidebar-events-container {
    display: flex;
    flex-direction: column;

    .extra-session-container {
        background-color: var(--bc);
        padding: 20px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        margin-top: 40px;
    }
}
