.personal-info-page-container {
    display: flex;

    .personal-info-left-container {
        width: 100%;
        flex: 3;
        border-right: solid 1px var(--light-gray);

        .back-button {
            border-bottom: solid 1px var(--light-gray);
        }

        .lead-buttons-container {
            display: flex;
            flex-direction: row-reverse;
            margin: 15px 15px 0 0;
            gap: 15px;
        }

        .lead-profile {
            display: flex;
            align-content: center;
            justify-content: center;
            border-bottom: solid 1px var(--light-gray);
        }

        .details {
            display: flex;
            padding: 40px 55px;
            gap: 41px;

            .left {
                display: flex;
                flex-direction: column;
                gap: 41px;
                width: 100%;

                .courses-taken {
                    display: flex;
                    gap: 3px;
                }
            }

            .right {
                display: flex;
                flex-direction: column;
                gap: 41px;
                width: 100%;
            }
        }
    }

    .right-container {
        flex: 4;
    }
}
