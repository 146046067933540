.payment-status-container {
    .financial-cards-container {
        padding: 15px 25px 20px;
        h3 {
            font-weight: 400;
            color: var(--new-dark-gray);
        }
        .financial-cards {
            display: flex;
            gap: 10px;
        }
    }

    .course-cards-container {
        padding: 0px 25px 15px;
        h3 {
            font-weight: 400;
            color: var(--new-dark-gray);
        }
        .course-cards {
            width: 625px;
            overflow-x: auto;
            display: flex;
            gap: 30px;
        }
    }

    .payment-status-table-container {
        margin-top: 15px;

        .table-header {
            margin-right: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            h3 {
                padding-left: 25px;
                font-weight: 400;
                color: var(--new-dark-gray);
            }

            .header-right-container {
                display: flex;
                align-items: center;
                gap: 10px;

                .activity-toggle-container {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    background-color: var(--bc);
                    padding: 5px 6px;
                    border-radius: 24px;
                }
            }
        }
        .sub-text {
            color: var(--dark-gray);
            font-weight: 600;
        }
        .review-button {
            text-align: center;
            background-color: var(--primary);
            padding: 3px 0px;
            border-radius: 20px;
            p {
                color: var(--white);
                font-size: 10px;
                font-weight: 600;
            }
        }
        .varified-review {
            background-color: var(--green);
        }
    }
}
