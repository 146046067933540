.create-post-container {
    background-color: var(--white);
    border-radius: 26px;
    .create-post-top {
        display: flex;
        padding: 5px 25px;
        .create-post-image-container {
            display: flex;
            justify-content: center;
            margin-top: 20px;
            flex: 1;
            flex-grow: 0;

            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }
        }
        .create-post-body-container {
            flex: 8;
            h3 {
                margin-top: 27px;
                margin-left: 20px;
            }
            .input-container {
                padding: 15px 10px 15px 20px;
            }
            .uploads-container {
                display: flex;
                align-items: start;
                justify-content: space-between;
                padding: 10px 10px 10px 10px;
                border-top: 1px solid var(--light-gray);

                .student-files-container {
                    h3 {
                        margin: 0;
                    }
                    .images-container {
                        display: flex;
                        gap: 10px;

                        .image-container {
                            display: flex;
                            flex-direction: row-reverse;
                            margin-top: 20px;

                            .icon {
                                cursor: pointer;
                                position: relative;
                                height: 8px;
                                width: 8px;
                                bottom: 7px;
                                right: 5px;
                                background-color: var(--fuchsia);
                                color: var(--white);
                                border-radius: 100%;
                                padding: 5px;
                            }
                            img {
                                width: 66px;
                                height: 66px;
                                border-radius: 8px;
                            }
                        }
                    }
                }
            }
        }
    }
    .create-post-bottom {
        display: flex;
        justify-content: space-between;
        border-top: 2px solid var(--bc);
        padding: 25px 35px 25px 40px;
        input {
            display: none;
        }
        .create-post-bottom-attachment {
            width: 175px;
            display: flex;
            align-items: center;
            gap: 10px;
            .icon {
                width: 25px;
                height: 25px;
                padding: 10px;
                border-radius: 100%;
            }
            .icon:hover {
                background-color: var(--light-gray);
                color: var(--primary);
                transition: 0.3s ease-in-out;
            }
        }
        .create-post-bottom-button {
            position: relative;
            display: flex;
            align-items: center;
            gap: 10px;
            .icon {
                width: 25px;
                height: 25px;
                padding: 10px;
                border-radius: 100%;
            }
            .icon:hover {
                background-color: var(--light-gray);
                color: var(--primary);
                transition: 0.3s ease-in-out;
            }
            .emojis-container {
                position: absolute;
                right: 140px;
                top: 40px;
                z-index: 1;
            }
            .create-post-bottom-attachment {
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .create-post-bottom {
        justify-self: start;
        padding: 12px 15px 15px 15px !important;
    }
}
