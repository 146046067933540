.admin-tab-bar-container {
    display: flex;
    height: 25px;
    padding: 23px 0px 10px 25px;
    gap: 30px;
    border-bottom: 1px solid var(--light-gray);
    h3 {
        margin: 0px;
    }
    .tab-container {
        a {
            text-decoration: none;
        }
        h3 {
            color: var(--gray-blue);
        }
        padding: 5px;
    }
    .tab-container:hover {
        color: var(--gray);
        padding: 5px;
        border-bottom: 0.5px solid var(--gray);
        transition: all 0.3s ease;
    }
    .tab-container.selected {
        h3 {
            color: var(--primary);
        }
        border-bottom: 3px solid var(--primary);
    }
}
