.dashboard-container {
    display: flex;
    .body-container {
        padding-top: 82px;
        flex: 6;
    }
}

@media screen and (max-width: 768px) {
    .dashboard-container {
        .sidebar-container {
            // left: 0;
            display: none;
        }
    }
}
