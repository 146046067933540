.personal-info-data-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
    p {
        margin: 0px;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
    }

    .title {
        p {
            color: var(--dark-gray);
        }
    }

    .text {
        p {
            color: var(--gray-blue);
        }
    }
}

.personal-info-data-score-container {
    display: flex;
    flex-direction: column;
    gap: 3px;
    p {
        margin: 0px;
        font-size: 15px;
        font-weight: 400;
        letter-spacing: 0px;
        text-align: left;
    }

    .title {
        p {
            color: var(--dark-gray);
        }
    }

    .scores-container {
        display: flex;
        flex-direction: column;
        gap: 3px;
        p {
            color: var(--gray-blue);
        }
    }
}
