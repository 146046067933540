.assignment-card-container {
    background-color: var(--white);
    border-radius: 24px;
    .top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        border-bottom: 1px solid var(--light-gray);
        margin-right: 0 !important;

        .left-container {
            display: flex;
            align-items: center;
            gap: 20px;

            .document-icon {
                background-color: var(--primary);
                padding: 6px 7px 4px 7px;
                border-radius: 100%;
                color: var(--white);
            }
            h2 {
                margin: 0px;
                color: var(--dark-gray);
                font-size: 1.4em;
            }
        }
        .right-container {
            display: flex;
            gap: 10px;
            align-items: center;

            span {
                color: var(--white);
                background-color: var(--primary);
                padding: 5px 12px;
                border-radius: 24px;

                p {
                    color: var(--white);
                }
            }
            span.mustard {
                background-color: var(--mustard);
            }
            span.fuchsia {
                background-color: var(--fuchsia);
            }
            span.green {
                background-color: var(--green);
                opacity: 80%;
            }

            .icon {
                cursor: pointer;
                padding: 5px;
                color: var(--dark-gray);
                border-radius: 24px;
            }
            .icon:hover {
                background-color: var(--light-gray);
                transition: all 0.3s ease;
            }
        }
    }
    .bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;

        .date-container {
            h3 {
                font-weight: 400;
                color: var(--black);
                margin: 0px;
            }
        }
        .button-container {
            flex-grow: 0;
            flex-shrink: 0;
            color: var(--dark-gray);
            border: 1px solid var(--light-gray);
            padding: 10px 7px;
            border-radius: 24px;
            h3 {
                margin: 0;
                color: var(--dark-gray);
            }
        }
        .button-container:hover {
            background-color: var(--primary);
            transition: 0.3s ease;
            h3 {
                color: var(--white);
            }
        }
    }
    .assignment-body-container {
        color: var(--dark-gray);
        padding: 20px;
        border-bottom: 1px solid var(--light-gray);
    }

    .teacher-block-uploads-container {
        .grading-container {
            border-bottom: solid 1px var(--light-gray);
            padding: 10px 0;

            .header {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        .teacher-uploads-container {
            padding: 20px;
            .header-container {
                display: flex;
                justify-content: space-between;
                align-items: start;
                h3 {
                    margin: 0;
                }
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }
        .student-uploads-container {
            border-top: 1px solid var(--light-gray);
            padding: 20px;
            h3 {
                margin: 0;
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

    .uploads-container {
        align-items: start;
        justify-content: space-between;

        .teacher-uploads-container {
            padding: 20px;
            h3 {
                margin: 0;
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }

        .student-uploads-container {
            border-top: 1px solid var(--light-gray);
            padding: 20px;
            h3 {
                margin: 0;
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }

        .new-uploads-container {
            border-top: 1px solid var(--light-gray);
            padding: 20px;
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h3 {
                    margin: 0;
                }
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }

        .button-container {
            display: flex;
            align-items: center;
            gap: 15px;

            .progress-bar {
            }
            input {
                display: none;
            }
        }
    }

    .submit-container {
        padding: 20px;
    }
}

@media screen and (max-width: 500px) {
    .assignment-card-container {
        padding: 5px 0px;

        h3 {
            font-size: 13px;
        }

        .top-container {
            padding: 20px 15px;

            .left-container {
                gap: 10px;
                h2 {
                    font-size: 14px;
                }
            }

            span {
                padding: 3px 5px !important;
                font-size: 12px;
            }
        }
        .bottom-container {
            padding: 20px 15px 6px;

            .date-container {
                h3 {
                    font-size: 13px !important;
                }
            }

            .button-container {
                padding: 5px 5px;
            }
        }
    }
}
