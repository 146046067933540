.Verification-container {
  max-width: 380px;

  .title {
    color: var(--primary);
    margin-top: 0;
  }
  h3 {
    font-weight: 400;
    .email {
      color: var(--primary);
    }
  }
  h4 {
    color: var(--dark-gray);
    .link {
      color: var(--primary);
      font-weight: 500;
      cursor: pointer;
      text-decoration: none;
    }
  }
  #button {
    margin-top: 60px;
  }
  .inputs {
    margin-top: 40px;
    flex-wrap: wrap;
    .verification-container {
      height: 90px;
      width: 100%;
    }
    .character {
      line-height: 90px;
      font-size: 48px;
      color: var(--black);
      border: 1px solid var(--light-gray);
      border-radius: 18px;
      margin-left: 15px;
      max-width: 78px;
    }

    .character:first-child {
      margin-left: 0;
    }

    .character--inactive {
      background-color: var(--white);
    }

    .character--selected {
      border: 1px solid var(--dark-gray);
    }

    .character.code-error {
      border: 1px solid var(--fuchsia);
    }
  }

  .code-error {
    h4 {
      color: var(--fuchsia);
    }
  }
}

@media (max-width: 425px) {
  .container {
    // max-height: 70px;
  }
  .character {
    line-height: 70px;
  }
}
