.personal-info-profile-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;

    img {
        width: 130px;
        height: 130px;
        border-radius: 100%;
    }

    h2 {
        margin: 0;
        font-size: 24px;
        font-weight: 500;
        line-height: 36px;
        letter-spacing: -0.2800000011920929px;
    }

    .icons {
        display: flex;
        gap: 24px;
        img {
            height: 40px;
            width: 40px;
        }
    }
}
