.typography {
  font-weight: 300;
  font-size: 14px;
  color: var(--black);
  margin: 0;
}

.typography.danger {
  color: var(--red);
}

.typography.black {
  color: var(--black);
}

.typography.primary {
  color: var(--primary);
}