:root {
  --primary: #6371e0;
  --primary-blue: #3e69a2;
  --blue: #315470;
  --fuchsia: #ec516e;
  --light-fuchsia: #fbaebc;
  --black: #262e3b;
  --white: #fff;
  --dark-gray: #696c71;
  --light-gray: #dfe2e5;
  --light-blue: #e1f4fe;
  --gray-blue: #97accc;
  --mustard: #f8cd5b;
  --secondary: #dfe2e5;
  --bc: #f7f7f7;
  --gold: #dfb300;
  --gray: #9ea2a2;
  --white-smoke: #f1f1f1;
  --green: #8be063;
  --dark-blue: #3f50d9;
  --new-light-gray: #a8a8a8;
  --new-dark-gray: #838383;
  --new-light-blue: #7da1c4;
  --light-yellow: #fff7db;
  --light-green: #f0fffa;
  --apple-green: #dcf6d0;
  --dark-green: #67ce67;
  --light-red: #fdeeee;
  --soft-light-blue: #7da1c433;
  --shade-01: #fafafc;
  --shade-02: #f1f1f1;
  --shade-03: #dfe2e5;
  --shade-04: #e5e5e5;
  --shade-05: #efefef;
  --bright-green: #8be0634d;
  --light-primary: #6371e04d;
}
