.session-tab-page-container{
    margin-top: -50px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    
    .navigation-bar{
        display: flex;
        gap: 7px;

        .selected{
            border-color: var(--primary);
            p{

                color: var(--primary);
            }
        }
        span{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 21px;
            width: 71px;
            border-radius: 30px;
            border: solid 1px var(--light-gray);
            padding: 7px 20px;

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 21px;
                color: var(--light-gray);
            }
        }
    }
}