.social-button {
  border: none;
  background: var(--bc);
  border-radius:41px;
  padding: 0px 0px;
  display: inline-block;
  cursor: pointer;
  display: flex;
  width: 150px;
  height: 45px;
  justify-content: center;
  align-items: center;
  transition: all .3s ease;

  img {
    margin-right: 20px;
    width: 20px;
    height: 20px;
  }
  h4 {
    font-weight: 400;
    padding-top: 2px;
  }
}

.social-button:hover {
  background: var(--white-smoke);
}