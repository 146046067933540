.confirm-schedule-container {
    padding: 30px 50px;
    .confirm-schedule-header {
        div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            span {
                color: var(--dark-gray);
                font-size: 0.8rem;
                background-color: var(--bc);
                padding: 5px 10px;
                border-radius: 10px;
            }

            span:hover {
                background-color: var(--light-gray);
                color: var(--black);
                transition: all 0.2s ease;
            }
        }
    }
}

@media screen and (max-width: 1200px) {
    .confirm-schedule-container {
        padding: 30px 20px;
    }
}

@media screen and (max-width: 992px) {
    .confirm-schedule-container {
        .confirm-schedule-header {
            .change-teacher-button {
                display: none;
            }
        }
    }
}
