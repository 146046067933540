.timeline-main-container {
    display: flex;
    align-items: center;
    .back-container {
        display: flex;
        h4 {
            a {
                cursor: pointer;
                text-decoration: none;
                color: var(--dark-gray);
            }
        }
        .icon {
            margin-right: 5px;
            margin-top: 22px;
        }
    }

    h4 {
        font-weight: 400;
        color: var(--dark-gray);
    }
    .timeline-container {
        flex: 2;
        margin: 50px 60px;
        height: 7px;
        background-color: var(--light-gray);
        border-radius: 18px;

        .timeline-progress {
            width: 0%;
            max-width: 100%;
            height: 100%;
            background-color: var(--primary);
            border-radius: 18px;
        }
        .timeline-items {
            margin-top: -15px;
            display: flex;
            justify-content: space-around;

            .timeline-item {
                position: relative;
            }

            .timeline-item::before {
                content: "";
                width: 20px;
                height: 20px;
                background-color: var(--light-gray);
                display: block;
                border-radius: 100%;
                border: 2px solid var(--white);
            }

            .timeline-item.active::before {
                background-color: var(--primary);
            }

            .timeline-item.active .timeline-content {
                color: var(--light-gray);
                background-color: var(--primary);
            }

            .timeline-item.active .timeline-content::before {
                background-color: var(--primary);
            }
        }
    }
}

@media screen and (max-width: 767px) {
    .timeline-main-container {
        margin-top: 50px;
        .back-container,
        .timeline-container,
        h4 {
            display: none;
        }
    }
}
