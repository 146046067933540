select {
  /* styling */
  background-color: white;
  border: thin solid var(--light-gray);
  border-radius: 25px;
  display: inline-block;
  font: inherit;
  line-height: 1.5em;
  padding: 0.65em 3.5em 0.65em 1em;
  width: 100%;

  /* reset */

  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}
select.dropdown {
  background-image: linear-gradient(45deg, transparent 50%, var(--light-gray) 50%),
    linear-gradient(135deg, var(--light-gray) 50%, transparent 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1.2em + 2px), calc(100% - 15px) calc(1.2em + 2px),
    calc(100% - 2.5em) -0.5em;
  background-size: 5px 5px, 5px 5px, 1px 3.5em;
  background-repeat: no-repeat;
}

select.dropdown:focus {
  background-image: linear-gradient(45deg, var(--primary) 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, var(--primary) 50%), linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1.2em, calc(100% - 20px) 1.2em, calc(100% - 2.5em) -0.5em;
  background-size: 5px 5px, 5px 5px, 1px 3.5em;
  background-repeat: no-repeat;
  border-color: var(--primary);
  outline: 0;
}
