.time-slot {
    span {
        font-weight: normal;
        font-size: 14px;
        line-height: 28px;
        background-color: var(--bc);
        color: var(--gray-blue);
        padding: 18px 15px;
        border-radius: 50px;
        cursor: pointer;
        transition: all 0.4s ease;
        width: 100%;
    }

    span:not(.denied):hover {
        background-color: var(--light-gray);
    }

    .selected {
        background-color: var(--primary) !important;
        color: var(--white) !important;
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .denied:not(.selected) {
        cursor: not-allowed;
    }
}

@media screen and (max-width: 768px) {
    .time-slot {
        span {
            padding: 10px 13px;
        }
    }
}
