.text-center { text-align: center; }
.text-start { text-align: start; }
.text-end { text-align: end; }
.text-left { text-align: left; }
.text-right { text-align: right; }

.text-rtl { direction: rtl; }
.text-ltr { direction: ltr; }

.fs { float: start; }
.fe { float: end; }
.fl { float: left; }
.fr { float: right }
.fn { float: none; }

.clear { clear: both; }