.student-search-card {
  display: flex;
  align-items: center;
  gap: 10px;
  height: 70px;
  padding: 0 10px;
}

.active {
  .student-search-card {
    .typography {
      color: #FFF;
    }
  }
}