.page {
    padding: 20px 0;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        margin: 0 0 18px;
        font-weight: 400;
    }

    h1,
    .h1 {
        font-size: 36px;
    }

    h2,
    .h2 {
        font-size: 30px;
    }

    p {
        font-size: 16px;
        margin: 0 0 20px;
    }

    .container {
        margin: 0 130px;
    }
}
