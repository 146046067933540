.manual-confirmation-modal {
    h2 {
        margin: 30px 0px;
    }
    text-align: center;

    .buttons {
        margin-top: 50px;
        display: flex;
        gap: 10px;
    }
}
