.teacher-classes-container {
    .filters-container {
        .search-filter-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .text-field {
                div {
                    display: none;
                }
                input {
                    padding: 7px 10px 7px 20px;
                }
            }
        }
    }
    .cards-container {
        .cards {
            padding: 0 20px 30px 20px;
        }
    }
}
