.student-classes-container {
    .sidebar-sessions-container {
        margin-left: 20px;
        h2 {
            font-weight: 400;
            font-size: 1.6em;
        }
        .sidebar-session-container {
            height: calc(100vh - 142px);
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
    }

    .todos-main-container {
        h2 {
            font-size: 1.5em;
            font-weight: 400;
            margin: 5px 0 15px 0;
        }
        .todos-container {
            display: flex;
            flex-direction: column;
            gap: 15px;
            margin-bottom: 50px;
        }
    }
    .next-session-container {
        display: none;
        margin-bottom: 50px;
        h2 {
            font-weight: 400;
            font-size: 1.6em;
            margin: 0 0 15px 0;
        }
    }
    .classes-container {
        h2 {
            font-size: 1.5em;
            font-weight: 400;
            margin: 0 0 15px 0;
        }
    }
}

@media screen and (max-width: 992px) {
    .student-classes-container {
        .sidebar-sessions-container {
            display: none;
        }
        .next-session-container {
            display: block;
        }
    }
}
