.coupon-container {
  display: flex;
  align-items: center;
  width: fit-content;
  gap: 12px;
  background-color: var(--primary);
  border-radius: 9px;
  padding: 5px 10px;

  .tag {
    height: 18px;
    width: 18px;
  }

  h4 {
    margin: 0;
    color: var(--white) !important;
  }

  .close-icon {
    cursor: pointer;
  }
}