.notification-card {
    padding: 10px;
    background-color: var(--white);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .top-container  {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title-container {

        }
        .date-container {

        }
    }
    .bottom-container {
        p {
            color: var(--dark-gray)
        }
    };
}