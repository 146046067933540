.cash-payment-container {
    padding: 15px;
    h2 {
        margin: 0;
        padding-bottom: 15px;
        font-weight: 400;
    }
    .upload-image-comntainer {
        text-align: center;
        input {
            display: none;
        }
        img {
            height: 250px;
            width: auto;
        }
    }
    .uploads-container {
        margin-top: 25px;
        display: flex;
        flex-direction: column;
        .new-uploads-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            
            .image-container {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--bc);
                border-radius: 8px;

                .right-container {
                    display: flex;
                    gap: 10px;
                }

                .icon {
                    cursor: pointer;
                    height: 20px;
                    width: 20px;
                    background-color: var(--fuchsia);
                    color: var(--white);
                    border-radius: 100%;
                    padding: 10px;
                }
                img {
                    width: 66px;
                    height: 66px;
                    border-radius: 8px;
                }
            }
        }
        .images-container {
            display: flex;
            flex-direction: column;
            gap: 20px;
            
            .image-container {
                padding: 10px 20px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                background-color: var(--bc);
                border-radius: 8px;

                .right-container {
                    display: flex;
                    gap: 10px;
                }

                .icon {
                    cursor: pointer;
                    height: 20px;
                    width: 20px;
                    background-color: var(--fuchsia);
                    color: var(--white);
                    border-radius: 100%;
                    padding: 10px;
                }
                img {
                    width: 66px;
                    height: 66px;
                    border-radius: 8px;
                }
            }
        }
    }

    .button-container {
        h3 {
            cursor: pointer;
            margin: 0;
            color: var(--primary);
            margin-bottom: 10px;
        }
    }
}
