.meta {
    display: flex;
    margin: 10px;
    span {
        display: block;
        p {
            color: var(--gray-blue);
            font-weight: 500;
            font-size: 13px;
        }
        span {
            font-weight: 500;
            color: var(--dark-gray);
            font-size: 13px;
        }
    }
    .icon {
        margin-top: 2px;
        width: 35px;
        height: 30px;
        display: inline-block;
        color: var(--gray-blue);
    }
}