.dashboard-sidebar-container {
    border: 1px solid #dfe2e5;
    border-top: none;
    height: calc(100vh - 142px);
    margin-top: 82px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 30px;
    position: sticky;
    top: 82px;
    left: 0;
    z-index: 10;
    background-color: #fff;
    width: 15vw;
    max-width: 200px;
    transition: all 0.4s ease;

    h3 {
        color: var(--white);
        margin: 0px;
    }

    .top-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .dashboard-button-container {
            display: flex;
            align-items: center;
            gap: 20px;
            background-color: var(--primary);
            border-radius: 30px;
            padding: 15px 20px;
            color: var(--white);
        }
    }
    .collapse-container {
        .collapse-button {
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            gap: 20px;
            h3 {
                color: var(--gray-blue);
                font-weight: 400;
                font-size: 1em;
            }
        }
    }
}

.dashboard-sidebar-container.collapse {
    width: 40px;
    h3 {
        display: none;
    }

    .collapse-container {
        .collapse-button {
            .arrow-icon {
                transform: rotate(180deg);
                transition: all 0.3s ease;
            }
        }
    }
}

@media screen and (max-width: 992px) {
}
