.student-recording-card-container {
    width: 100%;
    border-radius: 24px;
    background-color: var(--white);

    .recording-top-container {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        border-bottom: solid 2px var(--light-gray);
        padding: 20px 60px 24px 40px;

        .img-title-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25px;

            img {
                height: 40px;
                width: 40px;
            }

            h2 {
                margin: 0;
                white-space: nowrap;
                font-weight: 500;
                color: var(--dark-gray);
            }
        }

        .not-active-span {
            background-color: var(--dark-gray);
        }
        .active-span {
            background-color: var(--primary);
        }

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 10px;
            border-radius: 30px;

            h3 {
                margin: 0;
                color: var(--white);
                font-weight: 400;
            }
        }
    }

    .recording-bottom-container {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        padding: 16px 60px 16px 40px;

        .active-status-span {
            background-color: var(--apple-green);
        }

        .not-active-status-span {
            background-color: var(--light-blue);
        }

        .active-attendance-status {
            background-color: var(--apple-green);
        }

        .not-active-attendance-status {
            background-color: var(--light-fuchsia);
        }

        h3 {
            margin: 0;
            color: var(--dark-gray);
            font-weight: 400;
        }

        .right-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .session-status {
                border-radius: 25px;
                padding: 4px 8px;

                .active-status-body {
                    color: var(--dark-green);
                }

                .not-active-status-body {
                    color: var(--primary);
                }

                h4 {
                    margin: 0;
                    font-weight: 300;
                }
            }

            .attendance-status {
                border-radius: 25px;
                padding: 4px 8px;

                .active-attendance-status-body {
                    color: var(--dark-green);
                }

                .not-active-attendance-status-body {
                    color: var(--fuchsia);
                }

                h4 {
                    margin: 0;
                    font-weight: 300;
                }
            }

            .problem {
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 25px;
                padding: 4px 8px;
                background-color: var(--light-fuchsia);

                h4 {
                    margin: 0;
                    font-weight: 300;
                    color: var(--fuchsia);
                }
            }

            .edit {
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 25px;
                padding: 7px 7px;
                background-color: var(--light-primary);
            }

            .remove {
                display: flex;
                align-items: center;
                gap: 5px;
                border-radius: 25px;
                padding: 7px 7px;
                background-color: var(--light-fuchsia);
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .student-recording-card-container {
        .recording-top-container {
            padding: 15px 15px 15px 15px;

            .img-title-container {
                h2 {
                    font-size: 16px !important;
                }
            }

            span {
                padding: 0px 5px !important;

                h3 {
                    margin: 0;
                }
            }
        }

        .recording-bottom-container {
            padding: 14px 18px 14px 17px;
        }
    }
}
