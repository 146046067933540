.dashboard-table {
	display: table;
	width: 100%;
	position: relative;

	.table-row {
		display: table-row;
		width: 100%;
		clear: both;

		.table-col {
			display: table-cell;
			padding: 10px 10px;
			vertical-align: middle;
			font-size: 14px;
			outline: none;
		}

	}

	.table-head {
		.table-col {
			color: #707372;
			font-size: 15px;
			cursor: pointer;
			user-select: none;
			transition: all .3s ease;

			i.icon-chevron-down {
				font-size: 11px;
				position: relative;
				top: 2px;
				margin-left: 4px;
			}

			i.icon-chevron-down.flip {
				display: inline-block;
				transform: rotate(180deg);
				top: -2px;
			}

			i.icon-info {
				position: relative;
				top: 2px;
				margin-left: 4px;
				font-size: 14px;
			}
		}

		:hover {
			color: #333;
		}

	}

	.table-row-data {
		.table-col {
			padding: 20px 10px;
			border-top: 1px solid var(--light-gray);
		}
	}

	.loading {
		position: absolute;
		width: 100%;
		height: 100%;
		top: 50px;
		left: 0;
		background: rgba($color: #FFF, $alpha: .5);
		text-align: center;
		z-index: 10;
	}

	.profile {
		display: flex;
		align-items: center;

		.image {
			width: 40px;
			height: 40px;
			overflow: hidden;
			border-radius: 50%;
			margin-right: 15px;

			img {
				width: 100%;
			}
		}
	}

	.cols-visibility {
		position: absolute;
		top: 0;
		right: 60px;
		background: var(--white);
		border-radius: 8px;
		padding: 40px 55px;
		z-index: 75;
		opacity: 0;
		visibility: hidden;
		transition: all .3s ease;

		h2 {
			margin: 0 0 30px;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li {
				line-height: 45px;
			}
		}
	}

	.cols-visibility.show {
		opacity: 1;
		visibility: visible;
	}

}

.columns-overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba($color: #000000, $alpha: .2);
	z-index: 70;
	opacity: 0;
	visibility: hidden;
	transition: all .3s ease;
}

.columns-overlay.show {
	opacity: 1;
	visibility: visible;
}