.students-container {
  position: relative;
  .arrow-icon {
    cursor: pointer;
  }

  .top-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 61px;
  }

  .pagination {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    border-top: 1px solid var(--light-gray);
    padding: 10px 0;

    i {
      width: 32px;
      height: 32px;
      background-color: var(--white);
      border: 1px solid var(--gray);
      display: flex;
      align-items: center;
      justify-content: center;
      color: var(--dark-gray);
      border-radius: 50%;
      font-size: 11px;
      cursor: pointer;
      transition: all 0.3s ease;
    }
    i:hover {
      background-color: var(--shade-02);
    }

    .disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
}
.top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name-col {
  display: flex;
  align-items: center;
  gap: 10px;
}
