.admin-calendar-container {
    overflow: auto;

    .date-input {
        // background-color: red;
    }
}
.calender-appointment {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .calender-appointment-data {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-bottom: 3px;
        p {
            color: var(--white);
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}

.MuiModal-root {
    .Layout-popover {
        display: none;
    }
}

.sessions-count-container {
    border: 1px solid var(--secondary);
    padding: 6px 10px;
    border-radius: 24px;
    p {
        margin: 0;
    }
}

.appointment-container {
    cursor: pointer;
    position: absolute;
    height: 100%;
    width: 100%;
    overflow: hidden;
    box-sizing: border-box;
    border: 1px solid #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border-radius: 4px;
    background-color: #ec516e;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;

    .appointment-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px;
        .appointment-data {
            color: #fff;
            padding: 4px;
            padding-top: 1px;
            padding-left: 6px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: -webkit-box;
            display: -webkit-flex;
            display: -ms-flexbox;
        }
    }
}

.old {
    background-color: var(--mustard);
}

.blocked {
    background-color: var(--light-gray);
}
