.white-tab-bar-container {
    border-radius: 20px;
    display: flex;
    width: 100%;
    padding: 4px 5px;
    justify-content: space-between;
    background-color: var(--bc);
    

    h3 {
        margin: 0px
    }
    .tab-container {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 42px;
        height: 29px;
        padding: 0px 25px;

        h3 {
            font-size: 10px;
            font-weight: 500;
            color: var(--gray-blue);
            padding: 0px 15px;
        }


    }
    .tab-container:hover {
        background-color: var(--white-smoke);
        transition: all .3s ease;
        h3{
            color:var(--black)
        }
    }
    .tab-container.selected {
        background-color: var(--white);
        transition: all .3s ease;
        h3 {
            color: var(--black);
        }
    }
}
