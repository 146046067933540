.confirmation-time-slot {
    font-weight: normal;
    font-size: 15px;
    line-height: 28px;
    background-color: var(--bc);
    padding: 15px 25px 12px 25px;
    border-radius: 50px;
    margin-top: 10px;
    text-align: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: var(--gray-blue);

    .selected {
        background-color: var(--primary);
        transition: all 0.3s ease;
        cursor: pointer;
    }

    .actions {
        display: flex;
        gap: 15px;

        .icon-container {
            border-radius: 50%;
            background-color: var(--white);
            color: var(--gray-blue);
            width: 30px;
            height: 30px;
            display: inline-block;

            svg {
                width: 15px;
                position: relative;
                top: 2px;
            }
        }
    }

    .icon-container:hover {
        transition: all 0.3s ease;
        cursor: pointer;
    }
}

@media screen and (max-width: 768px) {
    .confirmation-time-slot {
        padding: 7px 10px;
    }
}
