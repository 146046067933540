.text-field {
    input {
        background: #FFFFFF;
        border: 1px solid var(--secondary);
        box-sizing: border-box;
        border-radius: 8px;
        padding: 15px 10px 15px 20px;
        width: 100%;
        font-weight: 400;
        outline: none;
        transition: all .3s ease;
    }

    input:focus {
        border: 1px solid var(--dark-gray);
        background: var(--bc);
    }

    input::placeholder {
        color: var(--gray);
        font-weight: 400;
    }

    input.secondary {
        border: 1px solid var(--secondary);
    }

    input.blue {
        border: 1px solid var(--primary);
    }

    
    input.default {
        border-radius: 8px;
    }
    
    input.rounded {
        border-radius: 25px;
    }
    
    input:disabled {
        background: var(--white-smoke);
        border-color: var(--light-gray)!important;
        cursor: not-allowed;
    }
    
    label {
        font-size: 10px;
        font-weight: 400;
        padding-bottom: 5px;
        padding-inline-start: 5px;
        display: inline-block;
    }
    
    input.fuchsia {
        border: 1px solid var(--fuchsia);
    }
    input.fuchsia::placeholder {
        color: var(--fuchsia);
    }

    .fuchsia {
        color: var(--fuchsia);
    }

    input:disabled {
        border: 1px solid var(--dark-gray);
    }

    .error {
        color: var(--fuchsia);
        font-weight: 300;
        font-size: 12px;
        margin: 5px 0 0;
        padding-inline-start: 5px;
    }
}