.line-chart {
  // box-shadow: 0px 1px 20px rgba(0, 0, 0, 0.1);
  background-color: var(--bc);
  border-radius: 20px;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: start;

  .chart-title-container {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .chart-title {
      color: var(--primary) !important;
      font-weight: 500;
      margin: 0;
    }
    .chart-sub-title {
      color: var(--new-light-gray);
      margin: 0;
    }
  }
}