.notes-container {
    .header {
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h3 {
            padding-left: 25px;
            font-weight: 400;
            color: var(--new-dark-gray);
        }
        .log-button {
            padding-right: 25px;
        }
    }

    .notes-table-container {
        margin-top: 20px;

        .sub-text {
            color: var(--dark-gray);
            font-weight: 600;
        }
        .title.sub-text {
            white-space: nowrap;
        }

        .comment {
            max-width: 100px;
        }
    }
}
