.search-modal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  z-index: 100;
  background-color: rgba($color: #000000, $alpha: 0.3);
  display: flex;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  animation: showSearchModal 0.3s ease forwards;

  .modal-container {
    margin-top: 50px;
    width: 500px;
    max-width: 90vw;
    height: max-content;
    background: var(--white);
    border-radius: 8px;
    overflow: hidden;
    animation: slideSearchModalContainer 0.3s ease forwards;

    .search-input {
      position: relative;

      i {
        position: absolute;
        top: 50%;
        left: 20px;
        transform: translateY(-50%);
        color: var(--gray);
      }

      input {
        height: 50px;
        width: 100%;
        outline: none;
        border: none;
        outline: none;
        padding-left: 50px;
        font-size: 15px;
      }
    }

    .search-content {
      border-top: 1px solid var(--shade-04);
      padding: 10px;

      .loader {
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .previous-searches {
        h3 {
          margin: 5px 0;
          font-size: 12px;
          font-weight: 500;
          color: var(--dark-gray);
        }

        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            line-height: 50px;
            cursor: pointer;
            color: var(--dark-gray);
            position: relative;
            padding: 0;

            i {
              padding-inline-end: 5px;
              color: var(--gray);
              position: relative;
              top: 2px;
              padding-left: 10px;
            }
          }
          .active {
            background-color: var(--primary);
            color: var(--white);
            border-radius: 8px;

            i {
              color: var(--white);
            }

            .icon-enter {
              position: absolute;
              top: calc(50% + 2px);
              right: 10px;
              transform: translateY(-50%);
              padding: 0;
            }
          }
        }
      }

      .search-results {
        ul {
          list-style: none;
          padding: 0;
          margin: 0;

          li {
            cursor: pointer;
            position: relative;
          }
          .active {
            background-color: var(--primary);
            border-radius: 8px;
            // display: flex;

            .icon-enter {
              position: absolute;
              top: calc(50% + 2px);
              right: 10px;
              transform: translateY(-50%);
              padding: 0;
              color: var(--white);
            }
          }
        }
      }

      .no-results {
        padding: 30px 0;
        text-align: center;
        color: var(--dark-gray);
      }
    }

    .helper-bar {
      background-color: var(--soft-light-gray);
      border-radius: 8px;
      margin-top: 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 50px;
      padding: 0 10px;
      font-size: 13px;
      color: var(--gray);

      .actions {
        display: flex;
        align-items: center;
        gap: 10px;

        i {
          padding-inline-end: 3px;
          position: relative;
          top: 2px;
        }
      }

      .quick-open {
        span {
          background: #fff;
          color: var(--dark-gray);
          border: 1px solid var(--shade-05);
          padding: 5px;
          border-radius: 5px;
        }
      }
    }
  }
}

@keyframes showSearchModal {
  to {
    opacity: 1;
    visibility: visible;
  }
}

@keyframes slideSearchModalContainer {
  to {
    margin-top: 100px;
  }
}
