.new-call-log-container {
    margin-top: 30px;
    display: flex;
    gap: 20px;
    .text-container {
        h3 {
            margin: 0 0 10px 0;
        }
        width: 100%;
    }
    .calendar-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
    }
}
