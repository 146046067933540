.react-select__control {
    border-radius: 50px!important;
}

.react-select__control.react-select__control--is-focused {
    border: 1px solid var(--dark-gray);
    background: var(--bc);
}

.react-select__control{
    border-color: var(--light-gray)!important;
}

.react-select__indicator-separator {
    display: none;

}
.react-select__menu {
    border-radius: 10px!important;
}

.react-select__value-container {
    padding: 10px 20px!important;
}

.react-select__indicator {
    padding-right: 20px!important;
}

.react-select__indicator.react-select__clear-indicator {
    padding-right: 0px!important;
}

.select-placeholder-text {
    font-size: 12px;
    color: var(--gray);
}

p {
    margin: 0;
}

.react-select__control .fuchsia{
    border-color: var(--fuchsia)!important;
}

input.fuchsia {
    border: 1px solid var(--fuchsia);
}

input.fuchsia::placeholder {
    color: var(--fuchsia);
}

.fuchsia {
    color: var(--fuchsia);
}

.error {
    color: var(--fuchsia);
    font-weight: 300;
    font-size: 12px;
    margin: 5px 0 0;
    padding-inline-start: 5px;
}

.react-select__menu-portal {
    z-index: 99999!important;
}