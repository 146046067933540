.notification-container {
    animation: showNotifications 0.3s ease-in-out forwards;
    width: 300px;
    position: absolute;
    padding: 10px;
    z-index: 10;
    border-radius: 17px;
    background-color: var(--bc);
    border: 1px solid var(--shade-03);
    color: var(--dark-gray);
    display: flex;
    flex-direction: column;
    gap: 10px;
    max-height: 364px;
    overflow-y: auto;
    top: calc(100% - 10px);
    right: 0;
    opacity: 0;
    visibility: hidden;

    .see-more {
        text-align: center;
        color: var(--primary);
        p {
            color: var(--primary);
            margin: 0;
        }
    }
}

@keyframes showNotifications {
    from {
        opacity: 0;
        visibility: hidden;
        top: calc(100% - 10px);
    }
    to {
        opacity: 1;
        visibility: visible;
        top: calc(100% + 10px);
    }
}

.notification-button-container {
    span {
        background-color: var(--primary);
        position: absolute;
        border-radius: 100%;
        height: 20px;
        width: 20px;
        bottom: 40px;
        left: 30px;
        display: flex;
        align-items: center;
        justify-content: center;

        p {
            color: var(--white);
        }
    }
}

@media screen and (max-width: 450px) {
    .notification-container {
        left: -85%;
        transform: translate(-50%, 0);
    }
}
