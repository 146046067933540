.course-statistics-container {
    display: flex;
    height: 230px;
    width: 164px;
    border-radius: 29px;
    background-color: var(--new-light-gray);

    .right-container {
        .upperside-design {
            z-index: -1000;
        }
    }

    .left-container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-width: 55%;
        max-width: 55%;

        .text {
            padding: 12px 0px 0px 19px;

            h3 {
                color: var(--white);
                margin-bottom: 18px;
                font-size: 22px;
            }

            h3.selected {
                color: var(--white);
            }

            .spans-container {
                display: flex;
                flex-direction: column;
                gap: 15px;
                width: 128px;
                span {
                    p {
                        font-size: 17px;
                        background-color: var(--white);
                        border-radius: 39px;
                        padding: 8px;
                        text-align: center;
                        color: var(--new-dark-gray);
                        white-space: nowrap;
                        display: inline-block;
                    }

                    p.selected {
                        background-color: var(--white);
                        color: var(--primary);
                    }
                }
                .course-button-span {
                    .course-button {
                        display: flex;
                        align-items: center;
                        gap: 8px;
                        background-color: var(--white-smoke);
                        border-radius: 39px;

                        p {
                            background-color: var(--primary);
                            padding: 6px;
                            color: var(--white);
                            font-size: 14px;
                            text-align: center;
                            white-space: nowrap;
                            display: inline-block;
                        }

                        p.selected {
                            background-color: var(--white);
                            color: var(--primary);
                        }

                        .arrow-icon {
                        }
                    }
                    .course-button.selected {
                        background-color: var(--white-smoke);
                        border-radius: 39px;
                    }
                }
            }

            h4 {
                margin: 0 0 0 2px;
                width: 150px;
                font-size: 10px;
                color: var(--mustard);
            }
        }

        .bottomside-design {
            max-height: 100%;
            max-width: 100%;
            display: flex;
            align-items: end;
            justify-content: left;
            overflow: hidden;
        }
    }
}

.course-statistics-container.selected {
    background-color: var(--primary);
}
