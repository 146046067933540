.card-container {
    background: #ffffff;
    box-shadow: 0px 8px 40px 5px rgba(0, 0, 0, 0.08);
    border-radius: 24px;
    padding: 30px 30px 10px 30px;
    margin-bottom: 50px;
    h2 {
        color: var(--primary);
    }
    .button-container {
        margin-top: 30px;
    }
    h3 {
        text-align: center;
        margin-top: 20px;
        margin-bottom: 0px;
        a {
            text-decoration: none;
            color: var(--primary);
        }
    }
}
