// ==== Margin ====
// Top
.mt { margin-top: 20px; }
.mt-05 { margin-top: 5px; }
.mt-1 { margin-top: 10px; }
.mt-15 { margin-top: 15px; }
.mt-2 { margin-top: 20px; }
.mt-3 { margin-top: 30px; }
.mt-4 { margin-top: 40px; }
.mt-5 { margin-top: 50px; }
.mt-6 { margin-top: 60px; }
.mt-7 { margin-top: 70px; }
.mt-8 { margin-top: 80px; }
.mt-9 { margin-top: 90px; }
.mt-10 { margin-top: 100px; }

// Right
.mr { margin-right: 20px; }
.mr-05 { margin-right: 5px; }
.mr-1 { margin-right: 10px; }
.mr-15 { margin-right: 15px; }
.mr-2 { margin-right: 20px; }
.mr-3 { margin-right: 30px; }
.mr-4 { margin-right: 40px; }
.mr-5 { margin-right: 50px; }
.mr-6 { margin-right: 60px; }
.mr-7 { margin-right: 70px; }
.mr-8 { margin-right: 80px; }
.mr-9 { margin-right: 90px; }
.mr-10 { margin-right: 100px; }

// Bottom
.mb { margin-bottom: 20px; }
.mb-05 { margin-bottom: 5px; }
.mb-1 { margin-bottom: 10px; }
.mb-15 { margin-bottom: 15px; }
.mb-2 { margin-bottom: 20px; }
.mb-3 { margin-bottom: 30px; }
.mb-4 { margin-bottom: 40px; }
.mb-5 { margin-bottom: 50px; }
.mb-6 { margin-bottom: 60px; }
.mb-7 { margin-bottom: 70px; }
.mb-8 { margin-bottom: 80px; }
.mb-9 { margin-bottom: 90px; }
.mb-10 { margin-bottom: 100px; }

// Left
.ml { margin-left: 20px; }
.ml-05 { margin-left: 5px; }
.ml-1 { margin-left: 10px; }
.ml-15 { margin-left: 15px; }
.ml-2 { margin-left: 20px; }
.ml-3 { margin-left: 30px; }
.ml-4 { margin-left: 40px; }
.ml-5 { margin-left: 50px; }
.ml-6 { margin-left: 60px; }
.ml-7 { margin-left: 70px; }
.ml-8 { margin-left: 80px; }
.ml-9 { margin-left: 90px; }
.ml-10 { margin-left: 100px; }



// ==== Padding ====
// Top
.pt { padding-top: 20px; }
.pt-05 { padding-top: 5px; }
.pt-1 { padding-top: 10px; }
.pt-15 { padding-top: 15px; }
.pt-2 { padding-top: 20px; }
.pt-3 { padding-top: 30px; }
.pt-4 { padding-top: 40px; }
.pt-5 { padding-top: 50px; }
.pt-6 { padding-top: 60px; }
.pt-7 { padding-top: 70px; }
.pt-8 { padding-top: 80px; }
.pt-9 { padding-top: 90px; }
.pt-10 { padding-top: 100px; }

// Right
.pr { padding-right: 20px; }
.pr-05 { padding-right: 5px; }
.pr-1 { padding-right: 10px; }
.pr-15 { padding-right: 15px; }
.pr-2 { padding-right: 20px; }
.pr-3 { padding-right: 30px; }
.pr-4 { padding-right: 40px; }
.pr-5 { padding-right: 50px; }
.pr-6 { padding-right: 60px; }
.pr-7 { padding-right: 70px; }
.pr-8 { padding-right: 80px; }
.pr-9 { padding-right: 90px; }
.pr-10 { padding-right: 100px; }

// Bottom
.pb { padding-bottom: 20px; }
.pb-05 { padding-bottom: 5px; }
.pb-1 { padding-bottom: 10px; }
.pb-15 { padding-bottom: 15px; }
.pb-2 { padding-bottom: 20px; }
.pb-3 { padding-bottom: 30px; }
.pb-4 { padding-bottom: 40px; }
.pb-5 { padding-bottom: 50px; }
.pb-6 { padding-bottom: 60px; }
.pb-7 { padding-bottom: 70px; }
.pb-8 { padding-bottom: 80px; }
.pb-9 { padding-bottom: 90px; }
.pb-10 { padding-bottom: 100px; }

// Left
.pl { padding-left: 20px; }
.pl-05 { padding-left: 5px; }
.pl-1 { padding-left: 10px; }
.pl-15 { padding-left: 15px; }
.pl-2 { padding-left: 20px; }
.pl-3 { padding-left: 30px; }
.pl-4 { padding-left: 40px; }
.pl-5 { padding-left: 50px; }
.pl-6 { padding-left: 60px; }
.pl-7 { padding-left: 70px; }
.pl-8 { padding-left: 80px; }
.pl-9 { padding-left: 90px; }
.pl-10 { padding-left: 100px; }