.financial-card-container {
  height: 60px;
  width: 100%;
  border-radius: 16px;
  padding: 15px;
  background-color: var(--bc);
  display: flex;
  gap: 18px;
  .left-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    gap: 7px;
    margin-top: 7px;
    .upper-container {
      h3 {
        margin: 0;
        color: var(--primary);
      }
    }
    .bottom-container {
      p {
        color: var(--gray-blue);
        margin: -5px 8px 8px 0px;
        font-size: 10px;
        color: var(--dark-gray);
        font-weight: 600;
      }
    }
  }
}

.negative {
  background-color: var(--light-fuchsia);
}
