.assignment-event {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 30px 30px;
    background-color: var(--bc);
    border-radius: 26px;
    .event-title {
        h3 {
            margin: 0;
        }
    }
    .event-button {
        
    }
}