.rate-session-container {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .session-info {
        margin-top: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        h3 {
            margin: 0;
        }
    }

    .rate-session-input {
        h3 {
            margin: 0 0 4px 0;
        }
    }

    .icons-container {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        h3 {
            margin: 10px 0 0 0;
        }
        .icon {
            cursor: pointer;
            align-items: center;
            display: flex;
            flex-direction: column;

            img {
                filter: grayscale(1);
                opacity: 0.6;
                transition: all 0.3s ease;
            }
            .icon-title {
                opacity: 0%;
            }
        }

        .active {
            img {
                opacity: 1;
                filter: grayscale(0);
            }
            .icon-title {
                color: var(--primary);
                opacity: 100%;
            }
        }
    }
}
