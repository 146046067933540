.admin-dashboard-container {
    display: flex;
    overflow-x: hidden;

    .body-container {
        position: relative;
        padding-top: 82px;
        width: 100%;
        height: calc(100vh - 80px);
        overflow-y: auto;
        overflow-x: hidden;
    }
}
