.options-menu {
  position: absolute;
  top: calc(100% - 10px);
  right: 0;
  background-color: var(--white);
  border: 1px solid var(--shade-03);
  border-radius: 8px;
  min-width: 200px;
  overflow: hidden;
  opacity: 0;
  visibility: hidden;
  animation: showOptionsMenuAnimation .3s ease-in-out forwards;

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      line-height: 50px;
      padding: 0 15px;
      font-size: 15px;
      cursor: pointer;
      display: flex;
      gap: 10px;
      align-items: center;
      color: var(--black-02);
      transition: all .3s ease;

      i {
        font-size: 14px;
        position: relative;
        top: 1.5px;
        color: var(--dark-purple);
        margin-right: 10px;
      }
    }

    li:hover {
      background-color: var(--bc);
    }
  }
}

@keyframes showOptionsMenuAnimation {
  from {
    opacity: 0;
    visibility: hidden;
    top: calc(100% - 10px);
  }
  to {
    opacity: 1;
    visibility: visible;
    top: calc(100% + 10px);
  }
}