.dashboard-navbar-container {
    border: 1px solid #dfe2e5;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #fff;
    z-index: 10;
    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        .nav-items {
            ul {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                gap: 20px;

                li {
                    list-style-type: none;
                    a {
                        text-decoration: none;
                        color: var(--dark-gray);
                    }
                }
            }
        }

        .logo-container {
            display: flex;
            justify-content: space-between;
            gap: 120px;

            .right-container {
                display: flex;
                align-items: center;
                gap: 15px;
                margin-left: 35%;

                .burger-icon {
                    display: none;
                }
            }

            .search {
                display: flex;
                align-items: center;
                gap: 20px;

                h3 {
                    color: var(--dark-gray);
                }
            }
        }

        .name-container {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-evenly;
            gap: 20px;
            margin-right: 40px;

            .icon {
                height: 20px;
                width: 20px;
                cursor: pointer;
                padding: 12px;
                border-radius: 100%;
                box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
            }
            .icon:hover {
                background-color: var(--bc);
                color: var(--white);
                transition: 0.3s ease-in-out;
            }
            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }

            .notification {
                position: relative;
            }

            .image {
                display: flex;
                align-items: center;
                gap: 7px;
                cursor: pointer;
                position: relative;

                > i {
                    color: var(--dark-gray);
                    font-size: 10px;
                    transition: all 0.3s ease;
                }

                .options-menu {
                    .options-menu-header {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        padding: 20px;
                        font-size: 13px;
                        line-height: 20px;
                        border-bottom: 1px solid var(--shade-04);

                        .name {
                            color: var(--dark-gray);
                        }
                    }
                }
            }
        }
    }
    @media screen and (max-width: 992px) {
        nav {
            .logo-container {
                display: flex;
                gap: 30px;

                .right-container {
                    margin-left: 20%;
                }
            }
            .name-container {
                h3 {
                    display: none;
                }
            }
        }
    }
}
