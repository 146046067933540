.post-container {
    border-radius: 24px;
    color: var(--dark-gray);
    background-color: var(--white);
    .post-header {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        padding: 20px 30px;
        border-bottom: 1px solid var(--light-gray);

        .exit-icon {
            padding: 10px 5px;
        }
        .post-header-left {
            display: flex;
            align-items: center;
            gap: 20px;
            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }
            .name-container {
                display: flex;
                flex-direction: column;
                gap: 10px;
                .post-header-title {
                    h4 {
                        margin: 0px;
                    }
                }
                .post-header-time {
                    h5 {
                        margin: 0px;
                        font-weight: normal;
                    }
                }
            }
        }
        .post-header-right {
            position: relative;
            .hide-tooltip {
                position: relative;
            }
            .show-tooltip .tooltiptext {
                visibility: visible;
            }
            .tooltiptext {
                visibility: hidden;
                position: absolute;
                right: 12px;
                top: 20px;
                text-align: center;
                z-index: 1;
                border-radius: 16px;
                background-color: var(--white);
                color: var(--dark-gray);
                display: flex;
                flex-direction: column;

                .edit {
                    background-color: var(--bc);  
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    justify-content: start;
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                    padding: 5px 15px 3px 12px;
                }
                .edit:hover {
                    background-color: var(--light-gray);
                    transition: all .3s ease;
                }
                .delete {
                    background-color: var(--bc);  
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    justify-content: start;
                    border-bottom-left-radius: 16px;
                    border-bottom-right-radius: 16px;
                    padding: 3px 15px 5px 12px;
                }
                .delete:hover {
                    background-color: var(--light-gray);
                    transition: all .3s ease;
                }
            }

            span {
                color: var(--white);
                background-color: var(--primary);
                padding: 5px 12px;
                border-radius: 24px;
            }
            span.mustard {
                background-color: var(--mustard);
            }
            span.fuchsia {
                background-color: var(--fuchsia);
            }
            span.green {
                background-color: var(--green);
                opacity: 80%;
            }

            .icon {
                cursor: pointer;
                padding: 5px;
                color: var(--dark-gray);
                border-radius: 24px;
            }
            .icon:hover {
                background-color: var(--light-gray);
                transition: all .3s ease;
            }
        }
    }
    .post-body {
        .post-text {
            h4 {
                margin: 0;
            }
            margin: 20px 20px 20px 75px;
        }
        .post-link {
            h4 {
                margin: 0;
            }
            margin: 20px 75px 20px 75px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
        .post-content {
            margin: 0;
            border-bottom: 1px solid var(--light-gray); 
        }
    }
    .post-bottom {
        display: flex;
        gap: 10px;
        padding: 20px 30px;
        .bottom-image {
            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }
        }
        .post-text-input {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0px 20px 0px 20px;
            width: 100%;
            background-color: var(--bc);
            border-radius: 12px;
            .input-container {
                flex: 1;
                width: 100%;
            }
            .icon-container {
                .icon {
                    width: 15px;
                    height: 15px;
                    padding: 10px;
                    border-radius: 100%;
                }
                .icon:hover {
                    background-color: var(--light-gray);
                    color: var(--primary);
                    transition: .3s ease-in-out;
                }
            }
            
        }
    }
}