.plans-container {
    align-items: center;
    .skip-container {
        h3 {
            margin: 0;
            text-align: center;
            a {
                text-decoration: none;
                color: var(--primary);
            }
        }
    }
}

@media screen and (min-width: 768px) {
    .back-container {
        display: none;
    }
}
