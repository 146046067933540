.personal-info-layout-container {
    display: flex;
    .side-container {
        // flex: 1.2;
    }
    .body-container {
        padding-top: 82px;
        flex: 6;
    }
}
