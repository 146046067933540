.sidebar-events-container {
    display: flex;
    flex-direction: column;
    gap: 50px;
    .sidebar-event-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
        h2 {
            margin-top: 0px;
            font-size: 22px;
        }
        img {
            max-width: 370px;
        }
    }
}

.class-body-container {
    display: flex;
    flex-direction: column;
    gap: 30px;

    .post-comments {
        padding: 15px 10px;
        max-height: 300px;
        overflow: hidden;
        overflow: scroll;
        display: flex;
        flex-direction: column-reverse;
        border-bottom: 1px solid var(--light-gray);
    }
    .attachments-container {
        border-top: 1px solid var(--light-gray);
        padding: 15px 10px 10px 10px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        
        h3 {
            margin-top: 0 !important;
            margin: 5px 0px 10px 20px;
        }

       .images-container {  
            display: flex;
            // gap: 10px;
            margin-left: 20px;

            .image-container {
                display: flex;
                flex-direction: row-reverse;

                .icon {
                    cursor: pointer;
                    position: relative;
                    height: 8px;
                    width: 8px;
                    bottom: 7px;
                    right: 5px;
                    background-color: var(--fuchsia);
                    color: var(--white);
                    border-radius: 100%;
                    padding: 5px;
                }
                img {
                    width: 66px;
                    height: 66px;
                    border-radius: 8px;
                }
                .file-icon {
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                        background-color: var(--primary);
                        border-radius: 100%;
                        padding: 5px;
                    }
                }
            }
        } 
    }
    
}

.editor-container-hidden {
    display: none;
}