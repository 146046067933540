.post-text-field {
    .post-input {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 400;
        border: none;
        box-sizing: border-box;
        border-radius: 8px;
        width: 100%;
        font-weight: 400;
        outline: none;
        transition: all 0.3s ease;
        resize: none;
        padding: 15px;
    }

    .gray {
        background-color: var(--bc);
    }
    .round {
        border-radius: 20px;
    }
    .primary {
        background-color: var(--white) !important;
        color: var(--primary);
    }
}
.post-text-field.error {
    .post-input {
        border: 1px solid var(--fuchsia);
    }
    p {
        color: var(--fuchsia);
    }
}
