.new-assignment-container {
    .title {
        h2 {
            margin: 0;
        }
    }
    .text-fields-container {
        display: flex;
        gap: 20px;
        margin-bottom: 30px;
        .date-container {
            width: 100%;
        }
        .mark-container {
            width: 100%;
        }
    }

    .editor-container {
        .tox {
            border: none;
            border-radius: 24px;
            background-color: var(--bc);
        }
        .tox-tinymce {
            background-color: var(--bc);
        }
        .tox-editor-container {
            background-color: var(--bc);
        }
    }

    .button-container {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        p {
            text-align: left;
        }

        div {
            display: flex;
            align-items: flex-start;
            gap: 15px;

            input {
                display: none;
            }
        }
    }

    .uploads-container {
        display: flex;
        justify-content: space-between;
        align-items: start;
        .images-container {
            display: flex;
            gap: 10px;
            
            .image-container {
                display: flex;
                flex-direction: row-reverse;
                margin-top: 20px;

                .icon {
                    cursor: pointer;
                    position: relative;
                    height: 8px;
                    width: 8px;
                    bottom: 7px;
                    right: 5px;
                    background-color: var(--fuchsia);
                    color: var(--white);
                    border-radius: 100%;
                    padding: 5px;
                }
                img {
                    width: 66px;
                    height: 66px;
                    border-radius: 8px;
                }
            }
        }
    }
}