.lead-status-container {
    h2 {
        margin: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 25px;

    .lead-status {
        display: flex;
        justify-content: space-between;
    }
}
