.assignment-card-container {
    background-color: var(--white);
    border-radius: 24px;
    .top-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;
        border-bottom: 1px solid var(--light-gray);

        .left-container {
            display: flex;
            align-items: center;
            gap: 20px;

            .document-icon {
                background-color: var(--primary);
                padding: 6px 7px 4px 7px;
                border-radius: 100%;
                color: var(--white);
            }
            h2 {
                margin: 0px;
                color: var(--dark-gray);
                font-size: 1.4em;
            }
        }
        .right-container {
            display: flex;
            gap: 10px;
            align-items: center;

            .hide-tooltip {
                position: relative;
            }
            .show-tooltip .tooltiptext {
                visibility: visible;
            }
            .tooltiptext {
                visibility: hidden;
                position: absolute;
                text-align: center;
                z-index: 1;
                border-radius: 16px;
                background-color: var(--white);
                color: var(--dark-gray);
                display: flex;
                flex-direction: column;

                .edit {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    justify-content: start;
                    border-top-left-radius: 16px;
                    border-top-right-radius: 16px;
                    padding: 5px 15px 3px 12px;
                }
                .edit:hover {
                    background-color: var(--light-gray);
                    transition: all 0.3s ease;
                }
                .delete {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    gap: 5px;
                    justify-content: start;
                    border-bottom-left-radius: 16px;
                    border-bottom-right-radius: 16px;
                    padding: 3px 15px 5px 12px;
                }
                .delete:hover {
                    background-color: var(--light-gray);
                    transition: all 0.3s ease;
                }
            }

            span {
                color: var(--white);
                background-color: var(--primary);
                padding: 5px 12px;
                border-radius: 24px;
            }
            span.mustard {
                background-color: var(--mustard);
            }
            span.fuchsia {
                background-color: var(--fuchsia);
            }
            span.green {
                background-color: var(--green);
                opacity: 80%;
            }

            .icon {
                cursor: pointer;
                padding: 5px;
                color: var(--dark-gray);
                border-radius: 24px;
            }
            .icon:hover {
                background-color: var(--light-gray);
                transition: all 0.3s ease;
            }
        }
    }
    .bottom-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 20px 30px;

        .date-container {
            h3 {
                font-weight: 400;
                color: var(--black);
                margin: 0px;
            }
        }
        .button-container {
            flex-grow: 0;
            flex-shrink: 0;
            color: var(--dark-gray);
            border: 1px solid var(--light-gray);
            padding: 10px 7px;
            border-radius: 24px;
            h3 {
                margin: 0;
                color: var(--dark-gray);
            }
        }
        .button-container:hover {
            background-color: var(--primary);
            transition: 0.3s ease;
            h3 {
                color: var(--white);
            }
        }
    }
    .assignment-body-container {
        color: var(--dark-gray);
        padding: 20px;
        border-bottom: 1px solid var(--light-gray);
    }

    .teacher-block-uploads-container {
        .teacher-uploads-container {
            padding: 20px;
            .header-container {
                display: flex;
                justify-content: space-between;
                align-items: start;
                h3 {
                    margin: 0;
                }

                .button-container {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                }
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }
        .student-uploads-container {
            border-top: 1px solid var(--light-gray);
            padding: 20px;
            h3 {
                margin: 0;
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }
    }

    .uploads-container {
        align-items: start;
        justify-content: space-between;

        .teacher-uploads-container {
            padding: 20px;
            h3 {
                margin: 0;
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }

        .student-uploads-container {
            border-top: 1px solid var(--light-gray);
            padding: 20px;
            h3 {
                margin: 0;
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                    .file-icon {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }

        .aleardy-submitted-container {
            padding: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid var(--light-gray);
        }

        .new-uploads-container {
            border-top: 1px solid var(--light-gray);
            padding: 20px;
            .top {
                display: flex;
                align-items: center;
                justify-content: space-between;
                h3 {
                    margin: 0;
                }
            }
            .images-container {
                display: flex;
                gap: 10px;

                .image-container {
                    display: flex;
                    flex-direction: row-reverse;
                    margin-top: 20px;

                    .icon {
                        cursor: pointer;
                        position: relative;
                        height: 8px;
                        width: 8px;
                        bottom: 7px;
                        right: 5px;
                        background-color: var(--fuchsia);
                        color: var(--white);
                        border-radius: 100%;
                        padding: 5px;
                    }
                    img {
                        width: 66px;
                        height: 66px;
                        border-radius: 8px;
                    }
                }
            }
        }

        .button-container {
            display: flex;
            align-items: center;
            gap: 15px;

            .progress-bar {
            }
            input {
                display: none;
            }
        }
    }

    .submit-container {
        padding: 20px;
    }
}
