// Poppins font-family
h1 { font-family: 'Poppins'; font-style: normal; font-weight: 500; font-size: 3.2rem; line-height: 4.2rem; color: var(--black);}
h2 { font-family: 'Poppins'; font-style: normal; font-weight: 500; font-size: 1.6rem; line-height: 2.4rem; color: var(--black); }
h3 { font-family: 'Poppins'; font-style: normal; font-weight: 500; font-size: 1rem; line-height: 1.5rem; color: var(--black); }
h4 { font-family: 'Poppins'; font-style: normal; font-weight: 400; font-size: 0.87rem; line-height: 1.4rem; color: var(--black); }

p { font-family: 'Poppins'; font-style: normal; font-weight: 400; font-size: 0.8rem; line-height: 1.2rem; color: var(--black); }
.body-sub-text { font-family: 'Poppins'; font-style: normal; font-weight: 400; font-size: 0.6rem; line-height: 1rem; color: var(--black); }

.sub-text { font-style: normal; font-weight: 400; font-size: 0.6rem; line-height: 1rem; color: var(--black); }

// Sarbun font-family 
h1.sarabun { font-family: 'Sarabun'; font-weight: 500; font-size: 3.2rem; line-height: 4.1rem; color: var(--black); }
h2.sarabun { font-family: 'Sarabun'; font-style: normal; font-weight: 500; font-size: 1.6rem; line-height: 2rem; color: var(--black); }
h3.sarabun { font-family: 'Sarabun'; font-style: normal; font-weight: 500; font-size: 1rem; line-height: 1.5rem; color: var(--black); }
h4.sarabun { font-family: 'Sarabun'; font-style: normal; font-weight: 400; font-size: 0.87rem; line-height: 1.2rem; color: var(--black); }

p.sarabun { font-family: 'Sarabun'; font-style: normal; font-weight: 400; font-size: 0.8rem; line-height: 1rem; color: var(--black); }
.body-sub-text.sarabun { font-family: 'Sarabun'; font-style: normal; font-weight: 400; font-size: 0.6rem; line-height: 0.8rem; color: var(--black); }

.sub-text.sarabun { font-family: 'Sarabun'; font-style: normal; font-weight: 400; font-size: 0.6rem; line-height: 0.8rem; color: var(--black); }
