.personal-info-back-button-container {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 32px;
    padding: 16.5px 20px 16.5px 28px;

    .icon {
        height: 25px;
        width: 12px;
    }

    .personal-info-name-container {
        h3 {
            margin: 0;
            font-size: 22px;
            font-weight: 400;
            letter-spacing: -0.2800000011920929px;
            text-align: left;
            color: var(--dark-gray);
        }
    }
}
