.statistics-test-container {
    border-radius: 20px;
    background-color: var(--bc);
    padding: 13px 18px;
    min-width: 200px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    .upper-container {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        .right {
            p {
                font-weight: 400;
                color: var(--dark-gray);
            }
            .selected {
                color: var(--white);
            }
        }
        .left {
            p {
                font-weight: 400;
                color: var(--dark-gray);
            }
            .selected {
                color: var(--white);
            }
        }
    }

    .bottom-container {
        h3 {
            margin: 0;
            font-weight: 400;
            color: var(--dark-gray);
            padding-left: 0px !important;
        }
        .selected {
            color: var(--white) !important;
        }
        .marks-container {
            display: flex;
            gap: 5px;
            color: var(--white);
            font-size: 0.8em;
        }
    }
}

.statistics-test-container.selected {
    background-color: var(--primary);
}
