.post-message-container {
    display: flex;
    padding: 7.5px;
   .message-image {
       display: flex;
       margin-top: 5px;
       margin-right: 5px;
       justify-content: center;
        img {
            width: 40px;
            height: 40px;
            border-radius: 100%;
        }
   }
   .message-container {
       display: flex;
       flex-direction: column;
       gap: 10px;
       width: 100%;
       background-color: var(--bc);
       border-radius: 12px;
       padding: 10px 25px 15px 20px;

       p {
           color: var(--dark-gray);
       }

    .message-header {
        display: flex;
        justify-content: space-between;
        gap: 10px;
        position: relative;
        
        .message-name {
            h4 {
                margin: 0px;
            }
        }
        .right-container {
            display: flex;
            gap: 10px;
            overflow: hidden;

            .message-time {
                h5 {
                    margin: 0px;
                    font-weight: normal;
                }
            }
            .options-container {
                // position: absolute;

                .hide-tooltip {
                    position: relative;
                }
                .show-tooltip .tooltiptext {
                    visibility: visible;
                }
                .tooltiptext {
                    visibility: hidden;
                    position: absolute;
                    right: 12px;
                    top: 20px;
                    text-align: center;
                    z-index: 1;
                    border-radius: 16px;
                    background-color: var(--white);
                    color: var(--dark-gray);
                    display: flex;
                    flex-direction: column;
    
                    .delete {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;
                        justify-content: start;
                        border-radius: 16px;
                        padding: 3px 15px 5px 12px;
                    }
                    .delete:hover {
                        background-color: var(--light-gray);
                        transition: all .3s ease;
                    }
                }
    
                span {
                    color: var(--white);
                    background-color: var(--primary);
                    padding: 5px 12px;
                    border-radius: 24px;
                }
                span.mustard {
                    background-color: var(--mustard);
                }
                span.fuchsia {
                    background-color: var(--fuchsia);
                }
                span.green {
                    background-color: var(--green);
                    opacity: 80%;
                }
    
                .icon {
                    cursor: pointer;
                    padding: 5px;
                    color: var(--dark-gray);
                    border-radius: 24px;
                }
                .icon:hover {
                    background-color: var(--light-gray);
                    transition: all .3s ease;
                }
            }
        }
    }
    .message-body {
        
    }
   }
}