.admin-todo-container {
    display: flex;
    width: 100%;
    height: calc(100vh - 140px);

    h2 {
        font-size: 24px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: -0.2800000011920929px;
        text-align: left;
    }

    .tasks-container {
        min-width: 287px;
        padding: 32px 16px;
        height: calc(100vh - 355px);
        border-radius: 24px;
        background-color: var(--bc);
        display: flex;
        flex-direction: column;
        gap: 8px;
        overflow-y: auto;
        align-items: center;
    }

    .todos {
        display: flex;
        width: 100%;
        flex: 2;
        padding: 28px 65px 43px 24px;
        gap: 45px;

        h2 {
            padding: 0px 0px 10px 15px;
            font-weight: 450;
            font-size: 24px;
        }

        .tasks-todos {
            width: 100%;
            flex: 1;
        }

        .finished-todos {
            width: 100%;
            flex: 1;
        }
    }

    .calendar {
        position: relative;
        padding-top: 32px;
        border-left: solid 1px var(--light-gray);
        flex: 1.1;

        h2 {
            font-weight: 450;
        }

        .calendar-background {
            position: absolute;
            top: 150px;
            left: 0;
            width: 100%;

            .line {
                background: var(--light-gray);
                height: 1px;
                margin-bottom: 30px;

                p {
                    color: var(--dark-gray);
                    padding: 7.5px 10px;
                }
            }
        }
        .calendar-cards {
            position: relative;
            z-index: 20px;
            padding: 0 30px 0 40px;
        }

        .appointment-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            background-color: var(--light-primary);
            .appointment-data {
                color: #fff;
                padding: 4px;
                padding-top: 1px;
                padding-left: 6px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: -webkit-box;
                display: -webkit-flex;
                display: -ms-flexbox;
            }
        }
    }
}
