.reuse-assignemnt-container {
    margin: 0px 40px;

    .search-container {
        margin-bottom: 30px;
    }
    .folders-container {
        background-color: var(--bc);
        border-radius: 24px;
        padding: 10px 30px 10px 50px;
        .folder-container {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            overflow-y: scroll;

            .folder-details {
                display: flex;
                align-items: center;
                gap: 10px;

                .folder-icon {
                    color: var(--primary);
                }
            }

            .assignments-container {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                gap: 10px;
                margin-left: 20px;
                h3 {
                    margin: 0;
                }
                .assignment {
                    display: flex;
                    align-items: center;
                    gap: 20px;

                    a {
                        color: var(--primary);
                    }
                }
            }
        }
    }
}