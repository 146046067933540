@charset "UTF-8";

@font-face {
  font-family: "delfina";
  src:url("../fonts/delfina.eot");
  src:url("../fonts/delfina.eot?#iefix") format("embedded-opentype"),
    url("../fonts/delfina.woff") format("woff"),
    url("../fonts/delfina.ttf") format("truetype"),
    url("../fonts/delfina.svg#delfina") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "delfina" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "delfina" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-bell:before {
  content: "\61";
}
.icon-bell-o:before {
  content: "\62";
}
.icon-check:before {
  content: "\63";
}
.icon-check-circle:before {
  content: "\64";
}
.icon-chevron-down:before {
  content: "\65";
}
.icon-chevron-left:before {
  content: "\66";
}
.icon-chevron-right:before {
  content: "\67";
}
.icon-chevron-up:before {
  content: "\68";
}
.icon-clipboard:before {
  content: "\69";
}
.icon-clock:before {
  content: "\6a";
}
.icon-collapse:before {
  content: "\6b";
}
.icon-flag:before {
  content: "\6c";
}
.icon-graph:before {
  content: "\6d";
}
.icon-grid:before {
  content: "\6e";
}
.icon-grid-o:before {
  content: "\6f";
}
.icon-help-circle:before {
  content: "\70";
}
.icon-pill:before {
  content: "\71";
}
.icon-plus-circle:before {
  content: "\72";
}
.icon-search:before {
  content: "\73";
}
.icon-syringe:before {
  content: "\74";
}
.icon-users:before {
  content: "\75";
}
.icon-users-o:before {
  content: "\76";
}
.icon-water-drop:before {
  content: "\77";
}
.icon-x:before {
  content: "\78";
}
.icon-emoji:before {
  content: "\79";
}
.icon-send:before {
  content: "\7a";
}
.icon-clipboard-1:before {
  content: "\41";
}
.icon-more:before {
  content: "\42";
}
.icon-enter:before {
  content: "\43";
}
.icon-navigate:before {
  content: "\44";
}
