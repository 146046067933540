.dots-loader {
    display: flex;
    gap: 5px;
    justify-content: center;

    span {
        display: inline-block;
        width: 10px;
        height: 10px;
        background-color: var(--primary);
        border-radius: 50%;
        animation: scaleDot1 1.2s ease infinite;
    }
    span:nth-child(2) {
        animation: scaleDot2 1.2s ease infinite;
    }
    span:nth-child(3) {
        animation: scaleDot3 1.2s ease infinite;
    }
}

@keyframes scaleDot1 {
    0%,
    33%,
    100% {
        transform: scale(1);
    }
    16% {
        transform: scale(1.4);
    }
}

@keyframes scaleDot2 {
    0%,
    33%,
    66%,
    100% {
        transform: scale(1);
    }
    49% {
        transform: scale(1.4);
    }
}

@keyframes scaleDot3 {
    0%,
    66%,
    100% {
        transform: scale(1);
    }
    82% {
        transform: scale(1.4);
    }
}

.section-loader {
    padding: 0 50px;
}

.overlay-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #fff, $alpha: 0.5);
    z-index: 100;
    max-height: 100vh;

    .dots-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

.overlay-loader {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba($color: #fff, $alpha: 0.5);

    .dots-loader {
        position: absolute;
        // top: 0;
        left: 50%;
    }
}

.circle-loader {
    width: 24px;
    height: 24px;
    border: 2.5px solid var(--primary);
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
