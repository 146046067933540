.filters-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .left-filters-container {
        margin: 30px 0px 10px 20px;
        display: flex;
        align-items: center;
        gap: 7.5px;

        .filter-container {
            border: 1px solid var(--secondary);
            padding: 5px 10px;
            border-radius: 24px;
            p {
                color: var(--secondary);
            }
        }
        .filter-container.selected {
            border: 1px solid var(--black);
            p {
                color: var(--black);
            }
        }
        .filter-container:hover {
            border: 1px solid var(--primary);
            transition: all 0.3s ease;
            p {
                color: var(--primary);
            }
        }

        .search-filter-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .text-field {
                div {
                    display: none;
                }
                input {
                    padding: 7px 10px 7px 20px;
                }
            }
        }
    }

    .filters-dropdown {
        margin-right: 10px;
    }
}
