.card {
    background: var(--bc);
    position: relative;
    border-radius: 10px;
    border: 1px solid var(--bc);
    transition: all 0.3s ease;

    .wrapper {
        padding: 20px 20px 0px 20px;
        display: flex;
        .avatar {
            img {
                margin-right: 20px;
                margin-top: 8px;
                width: 88px;
                height: 88px;
                border-radius: 50%;
                overflow: hidden;
                background-size: cover;
                background-position: center center;
            }
        }
        .details {
            .userinfo {
                strong {
                    display: block;
                    font-weight: 500;
                    font-size: 15px;
                }
                .address {
                    display: block;
                    font-size: 13px;
                    margin: 10px 0;
                    color: var(--dark-gray);
                }
                .rating {
                    display: block;
                    > div {
                        display: flex;
                        gap: 3px;

                        svg {
                            width: 13px;
                        }
                    }
                }
            }
            .price {
                position: absolute;
                top: 10px;
                right: 10px;
                background: var(--light-blue);
                color: var(--primary);
                font-weight: 600;
                padding: 10px 20px;
                border-radius: 20px;
                margin: 16px;
            }

            .metadeta {
                display: flex;
                margin: 0 -10px;
            }
        }
    }
    .link-container {
        text-align: center;
        border-top: 1px solid var(--light-gray);
        padding-top: 13px;
        border-radius: 0px 0px 10px 10px;
        transition: all 0.3s ease;
    }
    .link-container:hover {
        background-color: #f1f1f1;
    }
}
.card:hover {
    border: 1px solid var(--primary);
    cursor: pointer;
}
.selected-card {
    border: 1px solid var(--primary);
}

.one-teacher {
    .card:not(.selected-card) {
        opacity: 0.5;
        border: 1px solid var(--bc) !important;
        cursor: not-allowed;
    }
}

.disable-teacher-card {
    .card {
        opacity: 0.5;
        border: 1px solid var(--bc) !important;
        cursor: not-allowed;
    }
}

@media screen and (max-width: 1199px) {
    .card {
        .wrapper {
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .details {
                .userinfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
@media screen and (max-width: 1199px) {
    .card {
        .wrapper {
            flex-direction: column;
            align-items: center;
            gap: 10px;

            .details {
                .userinfo {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}
@media screen and (max-width: 600px) {
    .metadeta {
        flex-direction: column;
    }
}
