.attendance-container {
    h2 {
        margin: 0;
    }
    display: flex;
    flex-direction: column;
    gap: 25px;

    .attendance-option {
        display: flex;
        justify-content: space-between;
    }
}
