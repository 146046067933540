.teacher-give-mark-container {
    text-align: left;
    padding-bottom: 10px;
    h2 {
        margin: 0;
        font-weight: 400;
        font-size: 1.6em;
    }
    .text-fields-container {
        display: flex;
        justify-content: space-between;
        gap: 20px;
        .mark-container {
            width: 100%;
        }
        .original-mark-container {
            width: 100%;
        }
    }
}
