.todo-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px;
    background-color: var(--bc);
    border-radius: 8px;
    .todo-body {
        p {
            margin: 0;
            font-size: 1em;
            color: var(--dark-gray);
        }
        .rejection-message {
            margin-top: 5px;
            font-size: 0.7em;
            color: var(--fuchsia);
        }
    }
    .todo-button {
        display: flex;
        align-items: center;
        gap: 20px;
        input {
            display: none;
        }

        .span {
            background: var(--fuchsia);
            border-radius: 8px;
            padding: 5px 7px;
            p {
                color: var(--white);
            }
        }

        .remove-icon {
            padding: 10px;
            border-radius: 100%;
            cursor: pointer;
        }
        .remove-icon:hover {
            background-color: var(--light-gray);
            transition: all ease 0.3s;
        }
    }
}

@media screen and (max-width: 580px) {
    .todo-body {
        p {
            font-size: 0.8rem !important;
        }
    }

    .todo-button {
        gap: 10px !important;
    }
}
