.payment-header {
    text-align: center;
    margin: 0px 10px;
    h2 {
        margin: 0px;
    }
}
.payment-details {
    text-align: center;
}
.payment-options {
    display: flex;
    flex-direction: column;
    gap: 10px;
    .payment-box {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;
        border: 1px solid var(--light-gray);
        border-radius: 30px;
        padding: 10px 20px;
        gap: 15px;
        h4 {
            flex: 2;
            margin: 0px;
            color: var(--gray-blue);
            line-height: 21px;
        }
    }
}
.payment-summary-container {
    display: flex;
    flex-direction: column;
    padding: 30px;
    border: 1px solid var(--light-gray);
    border-radius: 15px;
    gap: 20px;

    .top-container {
        display: flex;
        flex-direction: column;
        gap: 20px;
        .course-cost {
            display: flex;
            justify-content: space-between;

            h4 {
                margin: 0;
            }
            .title {
                color: var(--dark-gray);
            }
        }
        .sub-total {
            display: flex;
            justify-content: space-between;

            h4 {
                margin: 0;
            }
            .title {
                color: var(--dark-gray);
            }
        }
    }

    .promo-code-container {
        padding: 20px 0;
        border-bottom: 1px solid var(--light-gray);
        border-top: 1px solid var(--light-gray);
        .code-button {
            background-color: var(--primary);
            width: fit-content;
            padding: 5px;
            border-radius: 3px;
            p {
                color: var(--white);
                font-weight: 500;
            }
        }

        .coupon {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        .code-input-container {
            display: flex;
            align-items: center;
            border: 1px solid var(--light-gray);
            border-radius: 4px;
            padding: 5px 15px 5px;
            justify-content: space-between;

            .code-input {
            }
            .apply-container {
                display: flex;
                align-items: center;
                gap: 5px;
                p {
                    margin: 0;
                    color: var(--primary);
                }
                :hover {
                    font-weight: 500;
                }
            }
        }
        .invalid-code {
            padding-top: 5px;
            p {
                margin: 0;
                color: var(--fuchsia);
            }
        }
    }

    .bottom-container {
        .total {
            display: flex;
            justify-content: space-between;

            h4 {
                margin: 0;
            }
            .title {
                color: var(--dark-gray);
            }
        }
    }
}

.disabled {
    img {
        filter: grayscale(1);
        opacity: 0.6;
    }
}
