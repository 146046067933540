.simple-checkbox {
  display: flex;
  align-items: center;
  gap: 10px;

  input {
    display: none;
  }

  label.icon {
    display: inline-block;
    position: relative;
    width: 20px;
    height: 20px;
    background: #fff;
    border: 1px solid var(--primary);
    border-radius: 3px;
    transition: all 0.3s ease;

    i {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%) scale(0);
      font-size: 12px;
      margin-top: 1.5px;
      color: #fff;
      transition: transform 500ms cubic-bezier(0.26, 2, 0.46, 0.71);
    }
  }

  .label {
    display: inline-block;
    position: relative;
    transition: all 0.3s ease;
  }

  input:checked ~ label.icon {
    background: var(--primary);
    border-color: var(--primary);

    i {
      transform: translate(-50%, -50%) scale(1.1);
    }
  }
}

.toggle-container {
  .switch {
    display: inline-block;
    height: 26px;
    position: relative;
    width: 50px;
  }

  .switch input {
    display: none;
  }

  .slider {
    background-color: #ccc;
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: 0.4s;
  }

  .slider:before {
    background-color: #fff !important;
    bottom: 4px;
    content: "";
    height: 18px;
    left: 6px;
    position: absolute;
    transition: 0.4s;
    width: 18px;
  }

  input:checked + .slider {
    background-color: var(--primary);
  }

  input:checked + .slider:before {
    transform: translateX(20px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}
