.session-problem-container {
    .session-problem-info {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 25px;
        gap: 20px;
    }
    .problem-section {
        .problem-section-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            border-bottom: 1px solid var(--light-gray);
        }
        .problem-section-body {
            display: flex;
            flex-direction: column;
            padding: 20px 0;
            border-bottom: 1px solid var(--light-gray);

            h3 {
                margin: 10px 0;
            }

            span {
                background-color: var(--light-primary);
                border-radius: 24px;
                h3 {
                    color: var(--primary);
                    margin: 0;
                    padding: 8px;
                }
            }

            .problem-note-container {
                padding: 13px;
                background-color: var(--light-gray);
                border-radius: 24px;
            }
        }
    }
}

.info-card {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--bc);
    gap: 10px;
    border-radius: 10px;
    padding: 10px 0px;

    .info-card-title {
        max-width: 123px;
    }

    .info-card-icon {
        margin-left: 30px;
    }

    h3,
    h4 {
        margin: 0;
    }
}
