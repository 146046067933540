.nav-container {
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.05);
    nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        .nav-items {
            ul {
                display: flex;
                align-items: center;
                padding: 0;
                margin: 0;
                gap: 20px;

                li {
                    list-style-type: none;
                    a {
                        text-decoration: none;
                        color: var(--dark-gray);
                    }
                }
            }
        }

        .name-container {
            z-index: 10;
            img {
                width: 40px;
                height: 40px;
                border-radius: 100%;
            }
            .image {
                display: flex;
                align-items: center;
                gap: 7px;
                cursor: pointer;
                position: relative;

                > i {
                    color: var(--dark-gray);
                    font-size: 10px;
                    transition: all 0.3s ease;
                }

                .options-menu {
                    .options-menu-header {
                        display: flex;
                        align-items: center;
                        gap: 15px;
                        padding: 20px;
                        font-size: 13px;
                        line-height: 20px;
                        border-bottom: 1px solid var(--shade-04);

                        .name {
                            color: var(--dark-gray);
                        }
                    }
                }
            }
        }
    }

    @media screen and (min-width: 992px) {
        .burger-icon {
            display: none;
        }
        .nav-items {
            display: block;
        }
    }

    @media screen and (max-width: 992px) {
        .burger-icon {
            display: block;
            position: fixed;
            right: 40px;
            top: 15px;
        }
        .nav-items {
            position: relative;
            display: none;
        }
        .nav-items.expanded {
            position: absolute;
            left: 50%;
            transform: translate(-50%, 0);
            animation-name: nav;
            animation-duration: 0.6s;
            display: block;
            z-index: 10;
            width: 100%;
            top: 80px;
            background-color: var(--bc);
            padding: 0 0 30px 0;
            border-bottom-left-radius: 30px;
            border-bottom-right-radius: 30px;
            ul {
                flex-direction: column;
                justify-content: center;
                gap: 0px;
            }

            ul li:last-child {
                padding-top: 15px;
            }
        }
    }

    @media screen and (max-width: 600px) {
        .burger-icon {
            right: 15px;
        }
    }
}

@keyframes nav {
    from {
        text-align: center;
        opacity: 0;
        top: 60px;
    }
    to {
        opacity: 100%;
        top: 80px;
    }
}
