.upcoming-session-container {
    display: flex;
    justify-content: space-between;
    padding: 30px 20px 30px 30px;
    background-color: var(--primary);
    border-radius: 20px;
    color: var(--white);
    min-width: 340px;

    h3 {
        margin: 0px;
        color: var(--white);
        font-weight: 400;
        font-size: 23px;
    }
    h4 {
        margin: 0px;
        color: var(--white);
        font-weight: 300;
    }
    .right-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .left-container {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }
    .button-container {
        display: flex;
        align-items: start;
    }
}
.disabled {
    background-color: var(--light-gray);
}

@media screen and (max-width: 768px) {
    .upcoming-session-container {
        min-width: 0;
        flex-direction: inherit;
        align-items: center;
        .date {
            display: none;
        }
        .title {
            display: none;
        }
    }
}
