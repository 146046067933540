.clickup-task-container{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13.5px;

    
    .circle{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        border-radius: 100%;
        border: solid 1px var(--light-gray);
        background-color: var(--primary);
        
        .icon{
            height: 13px;
            width: 10px;
        }
    }

    .simple-circle{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 16px;
        width: 16px;
        border-radius: 100%;
        border: solid 1px var(--gray-blue);
    }

    
    .height-container{
        display: flex;

        .color-column{
            width: 4px;
            border-radius: 8px;
        }
        
    
        .task-container{
            height: auto !important;
            width: 230px;
            border-radius: 8px;
            background-color: var(--white);
            display: flex;
            padding: 14px 12px 15px 26px;
            
    
            p{
                align-self: center;
                justify-self: center;
                width: 269px;
                font-size: 14;
                font-weight: 300;
                line-height: 24px;
                letter-spacing: -0.2800000011920929px;
                color: var(--dark-gray);
                
            }
    
        }
    }
}