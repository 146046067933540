.new-assignment-container {
    text-align: left;
    .title {
        h2 {
            margin: 0;
        }
    }
    .text-fields-container {
        display: flex;
        align-items: center;
        gap: 20px;
        margin-bottom: 30px;
        .date-container {
            width: 100%;
        }
        .mark-container {
            width: 100%;
        }
        .graded-switch {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            p {
                font-size: 10px;
            }
        }
    }

    .editor-container {
        .tox {
            border: none;
            border-radius: 24px;
            background-color: var(--bc);
        }
        .tox-tinymce {
            background-color: var(--bc);
        }
        .tox-editor-container {
            background-color: var(--bc);
        }
    }
}
