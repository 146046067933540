.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 15;

    .modal-main {
        overflow: scroll;
        position: fixed;
        background: white;
        height: fit-content;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 28px;
        transition: all 0.4s ease;
        display: flex;
        flex-direction: column;
        min-width: 300px;
        max-height: 800px;

        .modal-header-container {
            display: flex;
            flex-direction: row-reverse;
            .close-icon-container {
                position: relative;
                top: 20px;
                right: 20px;
                .close-icon {
                    cursor: pointer;
                    background-color: var(--light-gray);
                    border-radius: 100%;
                    padding: 3px;
                }
            }
        }

        .modal-body-container {
            margin: 0px 40px 40px 40px;
            h4 {
                color: var(--dark-gray);
            }

            .modal-image-container {
                .modal-image {
                    background-color: var(--light-blue);
                    padding: 30px;
                    border-radius: 100%;
                }
            }
        }
    }

    .full-height {
        height: 100%;
    }
}

.display-block {
    display: block;
}

.display-none {
    display: none;
}

@media screen and (max-width: 580px) {
    .modal-main {
        width: 100% !important;

        .modal-header-container {
            .close-icon-container {
                top: 54px !important;
            }
        }
    }
    .modal-body-container {
        margin: 0px 20px 30px 20px !important;

        h2 {
            font-size: 23px;
        }
    }
}

// @media screen and (max-height: 830px) {
//     .modal-main {
//     }
// }
