.admin-sessions-stats {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    gap: 15px;
    background-color: var(--bc);
    padding: 20px;
    border-radius: 20px;
}

.session-stat {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    background-color: var(--white);
    padding: 20px 25px;
    border-radius: 10px;
    word-wrap: break-word;

    .session-icon {
        margin-left: 30px;
    }

    .session-title {
        max-width: 123px;
    }

    h2,
    h4 {
        margin: 0;
    }
}
