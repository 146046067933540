.admin-open-calendar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 10px 0 20px;

    h3 {
        margin: 0;
    }
}

.confirmation-button {
    display: none;
}

@media screen and (max-width: 992px) {
    .confirmation-button {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
}

@media screen and (max-width: 600px) {
    .available-times {
        .time-slots {
            display: block;
        }
    }
}
