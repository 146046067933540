.personal-info-tab-bar-container {
    display: flex;
    padding: 17px 0 0;
    border-bottom: 1px solid var(--light-gray);
    justify-content: space-around;
    h3 {
        font-weight: 400;
        margin: 0px
    }
    .tab-container {
        a {
            text-decoration: none;
        }
        h3 {
            margin: 0;
            color: var(--gray-blue);
            padding-bottom: 13px;
        }
    }
    .tab-container:hover {
        h3 {
            color: var(--black);
            transition: all .3s ease;
        }
    }
    .tab-container.selected {
        display: flex;
        flex-direction: column;
        align-items: center;

        h3 {
            margin: 0;
            color: var(--primary);
            padding-bottom: 13px;
        }
        .border-bottom {
            border-bottom: 4px solid var(--primary);
            width: 50%;
            border-radius: 7px;
        }
    }
}