.session-duration-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    .session-duration-input {
        h2 {
            margin: 0 0 10px 0;
        }
    }
}
