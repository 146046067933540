.simple-statistics-container{
    display: flex;
    // height: 46px;
    // width: 201px;
    gap:16px;

    border-color: black;
    .left-container{
        img{
            width: 40px;
            height: 40px;
        }
    }

    .middle-container{
        display: flex;
        h2 {
            margin:0;
            font-weight: 400;
        }
    }

    .right-container{
        display: flex;
        align-items: center;

        h3{
            margin:0;
            font-size: 14px;
            font-weight: 300;
            line-height: 21px;
            letter-spacing: -0.2800000011920929px;
            text-align: left;
        }
    }
}