.schedule-modal-container {
    h3,
    h4 {
        // margin: 0;
    }
    .button-container {
        div > button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
        }
        .qrcode-container {
            h3 {
                margin-bottom: 10px;
            }
            img {
                width: 50%;
                height: 50%;
                border-radius: 12px;
            }
        }
    }
}
