.chip-label {
  font-weight: 500;
  font-size: 10px;
  padding: 4px 10px;
  border-radius: 7px;
  color: var(--dark-gray);
  background: rgba(229, 229, 229, 0.3);
  align-items: center;
  justify-content: center;
  white-space: nowrap;
}

.chip-label.mustard {
  background-color: #fff2d0;
  color: var(--mustard);
}
.chip-label.fuchsia {
  background-color: #f4a8b6;
  color: var(--fuchsia);
}
.chip-label.primary {
  background-color: #a6ade8;
  color: var(--primary);
}
.chip-label.blue {
  background-color: var(--soft-light-blue);
  color: var(--primary);
}
.chip-label.success {
  background-color: var(--light-green);
  color: var(--green);
}
.chip-label.secondary {
  background-color: var(--primary-blue);
  color: var(--white);
}
