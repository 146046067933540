.button {
  border: none;
  background: var(--primary);
  border-radius: 25px;
  padding: 15px 30px;
  display: inline-block;
  cursor: pointer;
  width: 100%;
  position: relative;
  white-space: nowrap;
}

.button.primary {
  background: var(--primary);
  color: #fff;
  width: 100%;
}

.button.fuchsia {
  background: var(--fuchsia);
  color: #fff;
}

.button.black {
  background: var(--black);
  color: #fff;
}

.button.white {
  background: var(--white);
  color: var(--primary);
  border: 1px solid var(--primary);
}

.button.white-fuchsia {
  background: var(--white);
  color: var(--fuchsia);
  border: 1px solid var(--fuchsia);
}

.button.dark_gray {
  background: var(--dark-gray);
  color: #fff;
}

.button.light_gray {
  background: var(--light-gray);
  color: #fff;
}

.button.light_blue {
  background: var(--light-blue);
  color: #fff;
}

.button.gray_blue {
  background: var(--gray-blue);
  color: #fff;
}

.button.mustard {
  background: var(--mustard);
  color: #fff;
}

.button.green {
  background: var(--green);
  color: #fff;
}

.button:disabled {
  cursor: not-allowed;
}
.button:not(.loading):disabled {
  background: var(--dark-gray);
  color: var(--light-gray);
}
.button.loading {
  min-height: 45px;
}
.button.small {
  padding: 10px 15px;
}
.button.medium {
  padding: 15px 30px;
}
.button.large {
  padding: 20px 35px;
}

.button.loading::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  top: 0;
  left: 0;
  border-radius: 50px;
  animation: dropEffect 1s ease infinite;
}

@keyframes dropEffect {
  0% {
    transform: scaleX(1) scaleY(1);
    opacity: 0.7;
  }
  100% {
    transform: scaleX(1.1) scaleY(1.35);
    opacity: 0;
  }
}

@media screen and (max-width: 768px) {
  .button {
    padding: 10px 15px;
  }
}
