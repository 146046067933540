.teacher-course-card-container {
    .teacher-course-card {
        min-height: 125px;
        width: 100%;
        background-color: var(--primary);
        color: var(--white);
        border-radius: 16px;
        padding: 10px 10px 10px 15px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin-top: 20px;
        .teacher-course-card-top {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .top-container {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 10px;

                span {
                    background: rgba(255, 255, 255, 0.3);
                    border-radius: 8px;
                    padding: 5px 6px;
                    p {
                        color: var(--white);
                    }
                }
                .access {
                    background: var(--fuchsia);
                    border-radius: 8px;
                    padding: 2px 3px;
                    p {
                        color: var(--white);
                    }
                }
            }
            h4 {
                color: var(--white);
                margin: 0px;
            }

            .options-container {
                display: flex;
                align-items: center;

                .hide-tooltip {
                    position: relative;
                }
                .show-tooltip .tooltip {
                    visibility: visible;
                }
                .tooltip {
                    display: flex;
                    visibility: hidden;
                    position: absolute;
                    text-align: center;
                    z-index: 1;
                    border-radius: 16px;
                    background-color: var(--white);
                    color: var(--dark-gray);
                    flex-direction: column;

                    .edit {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;
                        justify-content: start;
                        border-top-left-radius: 16px;
                        border-top-right-radius: 16px;
                        padding: 5px 30px 3px 12px;
                        p {
                            color: var(--dark-gray);
                        }
                    }
                    .edit:hover {
                        background-color: var(--light-gray);
                        transition: all 0.3s ease;
                    }
                    .delete {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        gap: 5px;
                        border-bottom-left-radius: 16px;
                        border-bottom-right-radius: 16px;
                        padding: 3px 15px 5px 12px;
                        p {
                            color: var(--dark-gray);
                        }
                    }
                    .delete:hover {
                        background-color: var(--light-gray);
                        transition: all 0.3s ease;
                    }
                }

                span {
                    color: var(--white);
                    background-color: var(--primary);
                    padding: 5px 12px;
                    border-radius: 24px;
                }
                span.mustard {
                    background-color: var(--mustard);
                }
                span.fuchsia {
                    background-color: var(--fuchsia);
                }
                span.green {
                    background-color: var(--green);
                    opacity: 80%;
                }

                .options-icon {
                    cursor: pointer;
                    padding: 5px;
                    color: var(--white);
                    border-radius: 24px;
                }
                .options-icon:hover {
                    background-color: var(--gray-blue);
                    transition: all 0.3s ease;
                }
            }
        }
        .teacher-course-card-bottom {
            display: flex;
            align-items: end;
            justify-content: space-between;
            p {
                color: var(--white);
                margin: 0px;
                font-size: 11px;
            }
        }
    }
    .fuchsia {
        background-color: var(--fuchsia);
    }
    .mustard {
        background-color: var(--mustard);
    }
    .primary {
        background-color: var(--primary);
    }
}
