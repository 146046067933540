.cash-transaction-validation-container {
    display: flex;
    flex-direction: column;
    padding: 32px 0px 0px;
    gap: 14px;
    .title {
        h2 {
            margin: 0;
            font-size: 24px;
            font-weight: 500;
            line-height: 36px;
        }
    }

    .submission-date {
        p {
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }
    }

    .cash-transaction-switch-card {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--bc);
        height: 68px;
        border-radius: 9px;
        padding: 0px 14px;

        .left {
            display: flex;
            gap: 10px;
            img {
                height: 40px;
                width: 40px;
                border-radius: 8px;
            }
        }

        .right {
            display: flex;
            align-items: center;
            gap: 31px;

            .buttons {
                display: flex;
                align-items: center;
                gap: 10px;
            }

            .selected {
                background-color: var(--primary);
                .icon {
                    color: var(--white);
                }
            }

            .not-selected {
                background-color: var(--light-gray);
                .icon {
                    color: var(--dark-gray);
                }
            }

            .icon-cricle {
                display: flex;
                justify-content: center;
                align-items: center;
                height: 40px;
                width: 40px;
                border-radius: 100%;

                .icon {
                    font-size: 22px;
                }
            }
        }
    }

    .rejection-reason {
        transition: all 0.3 ease-in;
        h3 {
            margin: 10px 5px;
            font-weight: 500;
        }
    }
}
