.rating-modal-container {
    padding: 20px;
    h2 {
        margin: 0;
        font-weight: 400;
    }
    .class-id {
        color: var(--primary);
    }
    .header-container {
        display: flex;
        justify-content: space-between;
    }
    .icons-container {
        display: flex;
        justify-content: space-between;
        .icon {
            cursor: pointer;
            align-items: center;
            display: flex;
            flex-direction: column;

            img {
                filter: grayscale(1);
                opacity: 0.6;
                transition: all 0.3s ease;
            }
            .icon-title {
                opacity: 0%;
            }
        }

        .active {
            img {
                opacity: 1;
                filter: grayscale(0);
            }
            .icon-title {
                color: var(--primary);
                opacity: 100%;
            }
        }
    }

    .text-field-container {
        .post-text-field {
            .post-input {
                padding: 10px;
            }
        }
    }
}
