.sessions-recordings-container {
    .course-cards-container {
        padding: 15px 25px;
        h3 {
            font-weight: 400;
            color: var(--new-dark-gray);
        }
        .course-cards {
            width: 625px;
            overflow-x: auto;
            display: flex;
            gap: 30px;
        }
    }

    .sessions-buttons-container {
        display: flex;
        flex-direction: row;
        padding: 15px 0px 0px 25px;
        gap: 15px;

        span {
            color: var(--dark-gray);
            font-size: 1rem;
            background-color: var(--bc);
            padding: 5px 10px;
            border-radius: 10px;
        }
        .selected {
            background-color: var(--light-gray);
            color: var(--black);
            transition: all 0.2s ease;
        }
        .span:hover {
            background-color: var(--light-gray);
            color: var(--black);
            transition: all 0.2s ease;
        }
    }

    .sessions-recordings-table-container {
        margin-top: 33px;
        h3 {
            padding-left: 25px;
            font-weight: 400;
            color: var(--new-dark-gray);
        }
        .sub-text {
            color: var(--dark-gray);
            font-weight: 600;
        }
        .change-button-container {
            display: flex;
            gap: 5px;
        }
    }
}
