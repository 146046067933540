.course-layout-container {
    display: flex;
    background-color: var(--bc);

    .body-container {
        padding: 35px;
        flex: 4;
        height: 100%;
        min-height: calc(100vh - 250px);
    }
    .course-sidebar-container {
        padding: 30px;
        flex: 2.1;
        border-left: 1px solid var(--light-gray);
        background-color: var(--white);
    }
}

@media screen and (max-width: 900px) {
    .course-sidebar-container {
        display: none;
    }
}
