// FIXME MAJOR Add box-sizing: border-box;
body { margin: 0; font-family: 'Poppins', 'Sarabun', Tahoma, Geneva, Verdana, sans-serif; font-size: 15px; line-height: 17px; }

.shine {
    background: var(--shade-03);
    background: linear-gradient(110deg, var(--shade-03) 8%, var(--shade-05) 18%, var(--shade-03) 33%);
    background-size: 200% 100%;
    animation: 1.2s shine linear infinite;
    min-height: 30px;
      border-radius: 8px;
    font-size: 0!important;
    min-width: 70px;
  }
  
  @keyframes shine {
    to {
      background-position-x: -200%;
    }
  }