.form-container {
    max-width: 320px;

    .auth-title {
        color: var(--primary);
        margin-top: 0;
    }
    h4 {
        color: var(--dark-gray);
    }
    .social-buttons-container {
        text-align: center;
        margin-top: 40px;
        h4 {
            color: var(--dark-gray);
        }

        .social-buttons {
            display: flex;
            justify-content: space-between;
        }
    }

    .terms-container {
        display: flex;
        justify-content: space-around;
        align-items: center;

        h4 {
            flex: 5;
            font-weight: 300;
        }

        input {
            flex: 1;
        }
    }
    h4 {
        font-weight: 400;
        a {
            color: var(--primary);
            text-decoration: none;
            font-weight: 500;
        }
    }

    .signup-button {
        margin-top: 30px;
        h4 {
            margin: 0;
        }
    }

    .privacy-policy {
        margin: 20px 0;
        color: var(--dark-gray);

        h4 {
            margin: 10px 0;
            font-weight: 400;
        }
    }
}
