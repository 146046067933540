.upcoming-event-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 40px;
    background-color: var(--bc);
    border-radius: 26px;
    padding: 20px 25px;
    .upcoming-event-top {
        display: flex;
        align-items: center;
        .title {
            flex: 3;
            h3 {
                font-weight: 400;
            }
        }
        .button-container {
            flex-grow: 0;
            flex-shrink: 0;
        }
    }
    .upcoming-event-bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        p {
            color: var(--gray-blue);
        }
        p {
            color: var(--gray-blue);
        }
    }
}