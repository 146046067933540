.student-reschedule-session-card-container {
    width: 100%;
    border-radius: 24px;
    background-color: var(--white);

    .recording-top-container {
        display: flex;
        gap: 20px;
        justify-content: space-between;
        border-bottom: solid 2px var(--light-gray);
        padding: 20px 60px 24px 40px;

        .img-title-container {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 25px;

            img {
                height: 40px;
                width: 40px;
            }
            h2 {
                margin: 0;
                white-space: nowrap;
                font-weight: 500;
                color: var(--dark-gray);
            }
        }

        .span-buttons-container {
            display: flex;
            align-items: center;
            gap: 10px;

            .not-active-span {
                background-color: var(--dark-gray);
                cursor: not-allowed;
            }
            .active-span {
                background-color: var(--primary);
                cursor: pointer;
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 10px;
                border-radius: 30px;

                h3 {
                    margin: 0;
                    color: var(--white);
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 24px;
                }
            }
        }
    }

    .recording-bottom-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 16px 60px 16px 40px;
        h3 {
            margin: 0;
            font-weight: 400;
            color: var(--dark-gray);
        }

        .recording-bottom-right-container {
            display: flex;
            align-items: center;
            gap: 10px;

            h4 {
                margin: 0;
            }

            .right-container {
                display: flex;
                align-items: center;
                gap: 10px;

                .active-status-span {
                    background-color: var(--apple-green);
                }

                .not-active-status-span {
                    background-color: var(--light-blue);
                }

                .session-status {
                    border-radius: 25px;
                    padding: 4px 8px;

                    .active-status-body {
                        color: var(--dark-green);
                    }

                    .not-active-status-body {
                        color: var(--primary);
                    }

                    h4 {
                        margin: 0;
                        font-weight: 300;
                    }
                }

                .attendance-status {
                    border-radius: 25px;
                    padding: 4px 8px;

                    .active-attendance-status-body {
                        color: var(--dark-green);
                    }

                    .not-active-attendance-status-body {
                        color: var(--fuchsia);
                    }

                    h4 {
                        margin: 0;
                        font-weight: 300;
                    }
                }

                .problem {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 25px;
                    padding: 4px 8px;
                    background-color: var(--light-fuchsia);

                    h4 {
                        margin: 0;
                        font-weight: 300;
                        color: var(--fuchsia);
                    }
                }

                .edit {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 25px;
                    padding: 7px 7px;
                    background-color: var(--light-primary);
                }

                .remove {
                    display: flex;
                    align-items: center;
                    gap: 5px;
                    border-radius: 25px;
                    padding: 7px 7px;
                    background-color: var(--light-fuchsia);
                }
            }

            .attendance-status {
                border-radius: 25px;
                padding: 4px 8px;
                background-color: var(--light-primary);

                .attendance-status-body {
                    color: var(--primary);
                }

                .active-attendance-status-body {
                    color: var(--dark-green);
                }

                .not-active-attendance-status-body {
                    color: var(--fuchsia);
                }

                h4 {
                    margin: 0;
                    font-weight: 300;
                }
            }

            .active-attendance-status {
                background-color: var(--apple-green);
            }

            .not-active-attendance-status {
                background-color: var(--light-fuchsia);
            }
        }
    }
}

@media screen and (max-width: 500px) {
    .student-reschedule-session-card-container {
        .recording-top-container {
            padding: 15px 15px 15px 15px;

            .img-title-container {
                h2 {
                    font-size: 16px !important;
                }
            }

            .span-buttons-container {
                display: flex;
                flex-direction: column-reverse;
                align-items: start;

                span {
                    padding: 5px;
                }
            }
        }

        .recording-bottom-container {
            padding: 14px 42px 14px 17px;
        }
    }
}
